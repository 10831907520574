import { Box, Icon, Text } from "@chakra-ui/react";
import { FC } from "react";
import { CModal } from "../Modal";
import { WiseImage } from "../Image";

import styles from './version.module.scss'
import { ScrollBarBox } from "../ScrollBox";

interface NewVersionProps {
  open: boolean
  releaseNotes: string[]
  onClose: () => void
  onUpdate: () => void
  isWindow?: boolean
  version?: string
}
export const NewVersion: FC<NewVersionProps> = (props) => {
  const { open, releaseNotes, onClose, onUpdate, isWindow, version } = props

  return <CModal isOpen={open} onClose={onClose}>
    <Box className={styles.version}>
      <Box className={styles.topBg}>
        <WiseImage src={require('@/assets/png/version_bg.png')} />
      </Box>
      <Box className={styles.content}>
        <Box className={styles.versionText}>
          <Text>目标版本：{version}</Text>
          <ScrollBarBox>
            {
              releaseNotes?.map(note => {
                return <Box className={styles.version_item}> •{note}</Box>
              })
            }
          </ScrollBarBox>
        </Box>
        <Box className={styles.tips}>
          <Icon as={require('@/assets/svg/tip_gray.svg').ReactComponent} />
          <Text>请点击“重启并更新”以体验最新版本。如果您暂时不想更新，可以选择忽略，客户端将在下次启动时自动更新。</Text>
        </Box>
        <Box className={styles.btns}>
          <Box className={styles.ignore} onClick={onClose}>忽略</Box>
          <Box className={styles.btn} onClick={onUpdate}>{isWindow ? '前往下载' : '重启并更新'}</Box>
        </Box>
      </Box>
    </Box>
  </CModal>
}