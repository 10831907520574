import { personalDb } from "@/db/personal";
import { fetchBills, fetchInforms, fetchPayOrders, fetchPromotionData, fetchPromotions, fetchVipPayOrders, postFeedbacks } from "@/api/personal";
import { fetchSignIn, fetchMonthBills, fetchTokenLogin, fetchAllWorktool, fetchCumulativeSignIn, updateCumulativeSignIn, fetchcheckPayResult } from '@/api/user'
import { fetchMineAgents } from "@/api/chat";
import { makeAutoObservable } from "mobx"
import { userStore } from "./user";

const { setUserInfo } = userStore;
let needRetry = true; //控制点数更新，防止无限更新点数

class PersonalStore {
  mineAgents: MineAgentsType = { myCollectionModels: [], myModels: [] };
  bills: BillsType[] = [];
  informs: informSchema[] = [];
  worktool: WorktoolSchema[] = [];
  promotions: PromotionsType[] = [];
  payOrders: PayOrdersType[] = [];
  payVipOrders: PayOrdersType[] = [];
  cumulativeSignIn: CumulativeSignInSchema = {
    _id: "",
    userid: "",
    username: "",
    month: "",
    three: "",
    seven: "",
    fourteen: "",
    twentyOne: "",
    twentySeven: ""
  };
  promotionData: PromotionData = { invitedAmount: 0, historyAmount: 0, residueAmount: 0 };
  AccumulatePoints: AccumulatePoints = {
    alltotalSum: 0,
    monthtotalSum: 0
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  //更新余额
  handleSuccess = async () => {
    const userdata = await fetchTokenLogin() as UserResult;
    setUserInfo(userdata);
  }

  setBills(bills: BillsType[], mineAgents: MineAgentsType, writeDb = false) {
    this.bills = bills;
    this.mineAgents = mineAgents;
    writeDb && personalDb.setBills(bills, mineAgents)
  }

  /** 获取点数列表 */
  getBills = async (starttime?: string, endtime?: string) => {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      personalDb.getBills().then(res => {
        // 防止http请求先获取到数据
        if (!this.bills.length && res) {
          this.setBills(res.bills, res.mineAgents)
        }
      })
      // const res = await fetchBills({ pageNum: 1, pageSize: 999 });
      // const mineRes = await fetchMineAgents()
      // this.setBills(res.data, mineRes, true)
      if (starttime && endtime) {
        const res = await fetchBills({ pageNum: 1, pageSize: 999, dateStart: starttime, dateEnd: endtime });
        const mineRes = await fetchMineAgents()
        this.setBills(res.data, mineRes, true)
      } else {
        const res = await fetchBills({ pageNum: 1, pageSize: 999 });
        const mineRes = await fetchMineAgents()
        this.setBills(res.data, mineRes, true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  setInforms(informs: informSchema[], mineAgents: MineAgentsType, writeDb = false) {
    this.informs = informs;
    this.mineAgents = mineAgents;
    writeDb && personalDb.setInforms(informs, mineAgents)
  }

  /** 获取消息记录 */
  getInforms = async (starttime?: string, endtime?: string) => {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      personalDb.getInforms().then(res => {
        // 防止http请求先获取到数据
        if (!this.informs.length && res) {
          this.setInforms(res.informs, res.mineAgents)
        }
      })
      if (starttime && endtime) {
        const res = await fetchInforms({ pageNum: 1, pageSize: 999, dateStart: starttime, dateEnd: endtime });
        const mineRes = await fetchMineAgents()
        this.setInforms(res.data, mineRes, true)
      } else {
        const res = await fetchInforms({ pageNum: 1, pageSize: 999 });
        const mineRes = await fetchMineAgents()
        this.setInforms(res.data, mineRes, true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  setPromotionData(data: PromotionData, mineAgents: MineAgentsType, writeDb = false) {
    this.promotionData = data;
    this.mineAgents = mineAgents;
    writeDb && personalDb.setPromotionData(data, mineAgents)
  }

  getPromotionData = async () => {
    try {
      personalDb.getPromotionData().then(res => {
        if (!this.promotionData && res) {
          this.setPromotionData(res.data, res.mineAgents)
        }
      })
      const res = await fetchPromotionData();
      const mineRes = await fetchMineAgents()
      this.setPromotionData(res, mineRes, true)
    } catch (err) {
      console.log(err)
    }
  }

  setPromotions(promotions: PromotionsType[], mineAgents: MineAgentsType, writeDb = false) {
    this.promotions = promotions;
    this.mineAgents = mineAgents;
    writeDb && personalDb.setPromotions(promotions, mineAgents)
  }

  getPromotions = async () => {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      personalDb.getPromotions().then(res => {
        // 防止http请求先获取到数据
        if (!this.promotions.length && res) {
          this.setPromotions(res.promotions, res.mineAgents)
        }
      })
      const res = await fetchPromotions({ pageNum: 1, pageSize: 999 });
      const mineRes = await fetchMineAgents()
      this.setPromotions(res.data, mineRes, true)
    } catch (err) {
      console.log(err)
    }
  }

  setPayOrders(payOrders: PayOrdersType[], mineAgents: MineAgentsType, writeDb = false) {
    this.payOrders = payOrders;
    this.mineAgents = mineAgents;
    writeDb && personalDb.setPayOrders(payOrders, mineAgents)
  }

  getPayOrders = async (starttime?: string, endtime?: string) => {
    this.handleSuccess();
    try {
      // 从未获取过历史记录去本地数据库中取一次
      personalDb.getPayOrders().then(res => {
        // 防止http请求先获取到数据
        if (!this.payOrders.length && res) {
          this.setPayOrders(res.payOrders, res.mineAgents)
        }
      })
      if (starttime && endtime) {
        const res = await fetchPayOrders({ pageNum: 1, pageSize: 999, dateStart: starttime, dateEnd: endtime });
        const mineRes = await fetchMineAgents()
        this.setPayOrders(res.data, mineRes, true)
      } else {
        const res = await fetchPayOrders({ pageNum: 1, pageSize: 999 });
        const mineRes = await fetchMineAgents()
        this.setPayOrders(res.data, mineRes, true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  setVipPayOrders(payVipOrders: PayOrdersType[], mineAgents: MineAgentsType, writeDb = false) {
    this.payVipOrders = payVipOrders;
    this.mineAgents = mineAgents;
    writeDb && personalDb.setVipPayOrders(payVipOrders, mineAgents)
  }

  getVipPayOrders = async (starttime?: string, endtime?: string) => {
    this.handleSuccess();
    try {
      // 从未获取过历史记录去本地数据库中取一次
      personalDb.getVipPayOrders().then(res => {
        // 防止http请求先获取到数据
        if (!this.payVipOrders.length && res) {
          this.setVipPayOrders(res.payVipOrders, res.mineAgents)
        }
      })
      if (starttime && endtime) {
        const res = await fetchVipPayOrders({ pageNum: 1, pageSize: 999, dateStart: starttime, dateEnd: endtime });
        const mineRes = await fetchMineAgents()
        this.setVipPayOrders(res.data, mineRes, true)
      } else {
        const res = await fetchVipPayOrders({ pageNum: 1, pageSize: 999 });
        const mineRes = await fetchMineAgents()
        this.setVipPayOrders(res.data, mineRes, true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  setMonthBills(AccumulatePoints: AccumulatePoints, mineAgents: MineAgentsType, writeDb = false) {
    this.AccumulatePoints = AccumulatePoints;
    this.mineAgents = mineAgents;
    writeDb && personalDb.setMonthBills(AccumulatePoints, mineAgents)
  }

  /** 获取累计点数 */
  getMonthBills = async () => {
    if (this.AccumulatePoints.alltotalSum === 0 && this.AccumulatePoints.monthtotalSum === 0) {
      this.handleSuccess();
    } else {
      if (needRetry && (this.AccumulatePoints.alltotalSum !== 0 || this.AccumulatePoints.monthtotalSum !== 0)) {
        needRetry = false;
        this.handleSuccess();
      }
    }
    try {
      // 从未获取过历史记录去本地数据库中取一次
      personalDb.getMonthBills().then(res => {
        // 防止http请求先获取到数据
        if (!this.AccumulatePoints && res) {
          this.setMonthBills(res.AccumulatePoints, res.mineAgents)
        }
      })
      const res = await fetchMonthBills();
      const mineRes = await fetchMineAgents()
      this.setMonthBills(res, mineRes, true)
    } catch (err) {
      console.log(err)
    }
  }

  async creatFeedback(contents: string, images: ImageItem[], phone: string) {
    const res = await postFeedbacks({ contents, images, phone, type: 'feedback' });
    this.handleSuccess();
    return res;
  }

  async creatReport(contents: string, images: ImageItem[], phone: string, info_array: string[]) {
    const res = await postFeedbacks({ contents, images, phone, type: 'report', info_array });
    this.handleSuccess();
    return res;
  }

  async creatSignIn() {
    const res = await fetchSignIn();
    this.handleSuccess();
    needRetry = true;
    return res;
  }

  setAllWorktool(worktool: WorktoolSchema[]) {
    this.worktool = worktool;
    personalDb && personalDb.setAllWorktool(worktool)
  }

  /** 获取所有收藏工具 */
  getAllWorktool = async () => {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      personalDb.getAllWorktool().then(res => {
        // 防止http请求先获取到数据
        if (!this.AccumulatePoints && res) {
          this.setAllWorktool(res.worktool)
        }
      })
      const res = await fetchAllWorktool();
      this.setAllWorktool(res)
    } catch (err) {
      console.log(err)
    }
  }

  setCumulativeSignIn(cumulativeSignIn: CumulativeSignInSchema) {
    this.cumulativeSignIn = cumulativeSignIn;
    personalDb && personalDb.setCumulativeSignIn(cumulativeSignIn)
  }

  /** 获取累计签到奖励列表 */
  getCumulativeSignIn = async () => {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      personalDb.getCumulativeSignIn().then(res => {
        // 防止http请求先获取到数据
        if (!this.AccumulatePoints && res) {
          this.setCumulativeSignIn(res.cumulativeSignIn)
        }
      })
      const res = await fetchCumulativeSignIn();
      this.setCumulativeSignIn(res.cumulativeSignIn)
    } catch (err) {
      console.log(err)
    }
  }

  async updateCumulativeSignIn(title: string) {
    const res = await updateCumulativeSignIn(title);
    this.handleSuccess();
    needRetry = true;
    return res;
  }

  async checkPayResult(_id: string) {
    const res = await fetchcheckPayResult(_id);
    this.handleSuccess();
    return res;
  }
}

export const personalStore = new PersonalStore();