import { Box, Flex, Icon, Text, Image } from "@chakra-ui/react";
import { FC } from "react";
import { observer } from "mobx-react-lite";
import styles from './index.module.scss';
import { creationStore, CreationType } from "@/store/creation";
import { useTranslation } from "react-i18next";



export const SubNav: FC = observer(() => {
  const { pageType, setPageType, showNav } = creationStore.publicStore;
  const { t } = useTranslation();
  const _ALL_MODELS = [
    { icon: 'draw_wise', name: t('wise') + 'MJ', desc: t('wise_desc'), active: CreationType.MJ },
    { icon: 'draw_sd', name: t('wise') + 'SD1.5', desc: t('wise_sd_desc'), active: CreationType.SD },
    { icon: 'draw_sd', name: t('wise') + 'SD3.0', desc: t('wise_sd3_desc'), active: CreationType.SD3 },
    { icon: 'draw_chat', name: t('wise') + 'E3', desc: t('wise_e3_desc'), active: CreationType.E3 },
    { icon: 'draw_video', name: t('wise') + 'GEN3', desc: t('wise_GEN3_desc'), active: CreationType.GEN3 },
    { icon: 'draw_wise', name: t('wise') + 'SUNO', desc: t('wise_SUNO_desc'), active: CreationType.SUNO },
  ]
  const _draw_Tools = [
    { name: t('PictureMagify'), icon: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/tools/icon/%E5%9B%BE%E7%89%87%E9%AB%98%E6%B8%85%E6%94%BE%E5%A4%A7.png', synopsis: '无损放大，细节清晰，画面锐利。', active: CreationType.ENLARGE },
    { name: t('PictureRemoveBG'), icon: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/tools/icon/%E5%9B%BE%E7%89%87%E8%83%8C%E6%99%AF%E7%A7%BB%E9%99%A4.png', synopsis: '一键操作，轻松去除图片背景，提升主体视觉效果。', active: CreationType.BG }
  ]

  return <Box className={`${styles.subnav} ${!showNav ? styles.hide : ''}`}>
    <Box className={styles.squareBtn} onClick={() => setPageType(CreationType.Square)}>
      <Icon className={styles.icon} as={require('@/assets/svg/square_orange.svg').ReactComponent} />
      <Text>{t('square')}</Text>
    </Box>
    {
      _ALL_MODELS.map(item => {
        return <Box
          className={`${styles.drawType} ${item.active === pageType ? styles.active : ''}`}
          key={item.active}
          onClick={() => {
            if (pageType !== item.active) {
              setPageType(item.active);
            }
          }}
        >
          {
            item.icon && <Icon className={styles.icon} as={require(`@/assets/svg/${item.icon}${item.active === pageType ? '_active' : ''}.svg`).ReactComponent} />
          }
          <Box className={styles.text}>
            <Text className={styles.name}>{item.name}</Text>
            <Text className={styles.desc}>{item.desc}</Text>
          </Box>
        </Box>
      })
    }
    <Box>
      <Text className={styles.titleText}>{t('ExploreMore')}</Text>
      {_draw_Tools.map((item) => {
        return (
          <Flex key={item.name} className={item.active === pageType ? styles.selecttoolBox : styles.toolBox}
            onClick={() => {
              if (pageType !== item.active) {
                setPageType(item.active);
              }
            }}>
            <Image src={item.icon} className={styles.avatar} />
            <Box className={styles.contentbox}>
              <Text className={styles.contName}>{item.name}</Text>
              <Text className={styles.contSynopsis}>{item.synopsis}</Text>
            </Box>
          </Flex>
        )
      }
      )}
    </Box>
  </Box>
})