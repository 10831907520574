import { observer } from "mobx-react-lite";
import { FC } from "react";
import { ChatHistory } from "./components/History";
import { Box, Icon } from "@chakra-ui/react";
import ChatHeader from "./components/Header";
import ChatContent from "./components/Content";
import InputArea from "./components/InputArea";
import { WiseImage } from "@/components/Image";
import { chatStore } from "@/store/chat";

import styles from './index.module.scss'

const Chat: FC = () => {
  const { isHideHistoryNav, setIsHideHistoryNav } = chatStore;
  return <Box className={styles.chat}>
    <ChatHistory />
    <Box className={`${styles.nav}  ${isHideHistoryNav ? styles.hide : ''}`} onClick={() => setIsHideHistoryNav(!isHideHistoryNav)}>
      <Box className={styles.navBox}>
        <WiseImage className={styles.navOp} src={require('@/assets/png/nav_op_bg.png')} />
        <Icon className={styles.navOpIcon} as={require('@/assets/svg/right.svg').ReactComponent} />
      </Box>
    </Box>
    <Box className={styles.content}>
      <ChatHeader />
      <ChatContent />
      <InputArea onScrollToBottom={() => { }} />
    </Box>

  </Box>
}

export default observer(Chat)