import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, Icon, Image, Text, useDisclosure } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import styles from "./index.module.scss";
import { ScrollBarBox } from "@/components/ScrollBox";
import React from "react";
import i18n from "@/utils/i18n";
import { useTranslation } from "react-i18next";

export const ComTitle: FC<any> = observer(({
  color,
  backgroundColor,
  icon,
  title,
  historyCache,
  setViewhistory,
  bindprompt,
  onDelete,
  historytitle,
  historycontent,
  creatTime,
  isVIP
}: {
  backgroundColor: string;
  icon: string;
  title: string;
  historyCache: any[];
  setViewhistory: (...args: any[]) => void;
  bindprompt: any;
  onDelete?: (id: string) => void;
  historytitle?: string;
  historycontent?: string;
  creatTime?: string;
  color?: boolean;
  isVIP?: boolean
}) => {
  interface YourComponentProps {
    mapList: any[];
    Select: string;
    onClick: (id: string) => void;
  }

  let parentField = '';
  let childField = '';
  if (historytitle && historytitle.includes('.')) {
    const fields = historytitle.split('.');
    // 假设 historytitle 总是 "parent.child" 这样的格式
    parentField = fields[0];
    childField = fields[1];
  } else {
    parentField = historytitle!
  }

  const { isOpen: isOpenSlider, onClose: onCloseSlider, onOpen: onOpenSlider } = useDisclosure();
  const { t } = useTranslation();


  const YourComponent = React.memo((props: YourComponentProps) => {
    const sortedList = props.mapList.slice().sort((a, b) => new Date(b.createdate).getTime() - new Date(a.createdate).getTime());
    return (
      <Box className={styles.comsBox}>
        <ScrollBarBox>
          {sortedList.map((item, index) => {
            const value = historycontent ? item[historycontent] : item.contents
            const isFunctionCall = Array.isArray(value);
            let valStr = ''
            if (isFunctionCall) {
              value.forEach(str => {
                const noText = str.startsWith('\n\n```function_call') || str.endsWith('```\n\n') || str.startsWith('\n\n```finish_reason')
                if (!noText) {
                  valStr = str;
                }
              })
            }
            const val = (isFunctionCall) ? valStr : value;
            const riskValue = item.contentCheck?.status !== false ? item.askCheck?.status !== false ? val : (`${t("creation.TextMayContain")}${item.askCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')}${t('creation.NoConsume')} ` || t('NoConsume'))
              : (`${t("ContentMayContains")}:${item.contentCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ` || t('ContentRiskBlocked'))
            return (
              <Flex key={item._id} className={styles.hismodel}
                onClick={() => {
                  setViewhistory(item);
                  onCloseSlider();
                }}>
                <Box >
                  <Text color={(item.contentCheck?.status === false || item.askCheck?.status === false) ? '#FF5852' : '#333'} className={styles.single_line_text}>
                    {title === '在线视频总结' && '视频地址：'}
                    {historytitle ? (childField.length > 0 ? item[parentField][childField] : item[parentField]) : item.template}
                  </Text>
                  <Flex justifyContent={'center'} flexDirection={'row'} alignItems={'center'}>
                    {(item.contentCheck?.status === false || item.askCheck?.status === false) &&
                      <Icon as={require('@/assets/svg/toolHisError.svg').ReactComponent} w={'20px'} height={'20px'} mr={'5px'} />
                    }
                    <Text w={(item.contentCheck?.status === false || item.askCheck?.status === false) ? '440px' : '460px'} color={(item.contentCheck?.status === false || item.askCheck?.status === false) ? '#FF5852' : '#999'} className={styles.singles_line_text}>
                      {riskValue}
                    </Text>
                  </Flex>
                  <Box className={styles.deletebox}>
                    <Text>
                      {creatTime ?
                        item[creatTime] ? (
                          item[creatTime].toLocaleString()
                            .replace('Z', '')
                            .replace('T', ' ')
                            .substring(0, 16)
                        ) : (
                          ''
                        )
                        :
                        item.createdate ? (
                          item.createdate.toLocaleString()
                            .replace('Z', '')
                            .replace('T', ' ')
                            .substring(0, 16)
                        ) : (
                          ''
                        )
                      }
                    </Text>
                    <Icon as={require('@/assets/svg/toolsicon/delete.svg').ReactComponent}
                      onClick={async (event) => {
                        event.stopPropagation();
                        onCloseSlider();
                        onDelete && onDelete(item._id);
                      }} />
                  </Box>
                </Box>
              </Flex>
            )
          })}
        </ScrollBarBox>
      </Box>
    );
  });
  return (
    <Flex className={styles.toolbox}>
      <Flex className={styles.databox}>
        <Image className={styles.avatar} src={icon} />
        <Text color={color ? '#fff' : '#333'}>{title}</Text>
      </Flex>

      {onDelete && (
        <Flex>
          <Text className={styles.hisbut} onClick={onOpenSlider}>{t('Histories')}</Text>
          <Drawer isOpen={isOpenSlider} placement="right" onClose={onCloseSlider}>
            <DrawerOverlay className={styles.background} onClick={onCloseSlider} />
            <DrawerContent className={styles.hiscontent}>
              <Flex className={styles.hisflex}>
                <Box className={styles.histitle}>
                  {t('HistoryRecord')}（{historyCache.length}）
                </Box>
                <YourComponent
                  mapList={historyCache}
                  Select={bindprompt.current === null ? '' : bindprompt.current!.value}
                  onClick={(id: string) => {
                    onCloseSlider();
                  }}
                ></YourComponent>
              </Flex>
            </DrawerContent>
          </Drawer>
        </Flex>
      )}
    </Flex>
  )
})