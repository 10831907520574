import { Box, Flex, Icon, Text, Textarea, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss"
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";
import { RobotIcon } from "../../WechatRobot";

export const ComTextarea: FC<any> = observer(({
  title,
  prompt,
  textvalue,
  textchange,
  maxCount,
  rows,
  required,
  systemPromptTip
}: {
  title: string;
  prompt: string;
  textvalue: string;
  textchange: (e: string) => void;
  maxCount: number;
  rows: number;
  required?: boolean;
  systemPromptTip?: string;
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (textvalue.length > 0 && textvalue.trim() !== '') {
      setErrorMessage('');
    }
  }, [textvalue])
  const handleBlur = () => {
    setIsFocused(false)
    if (required === true && textvalue.trim() === '') {
      setErrorMessage('请输入内容');
    } else {
      setErrorMessage('');
    }
  };
  let parts = [''];
  if (required === true) {
    if (!title.includes('*')) {
      title = title + '*';
      parts = title.split('*');
    } else {
      parts = title.split('*');
    }
  } else {
    parts = title.split('*');
  }
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        {parts.map((part: any, index: any) => (
          <Text className={styles.title} key={index}>
            {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
          </Text>
        ))}
        {systemPromptTip && ( //跟在标题后的提示文字
          <Tooltip className={styles.titletipsbox} >
            <Icon onClick={() => setOpen(true)} />
          </Tooltip>
        )}
      </Box>
      <Box pb={maxCount > 100 ? '14px' : '0px'} className={`${styles.textareabox} ${isFocused ? styles.focused : errorMessage ? styles.error : ''}`}>
        <Textarea
          className={maxCount > 100 ? styles.textarea : styles.Onetextarea}
          rows={rows}
          placeholder={!isFocused ? prompt : ''}
          value={textvalue}
          onChange={(e) => textchange(e.target.value)}
          onBlur={handleBlur}
          onFocus={() => setIsFocused(true)}
          maxLength={maxCount}
        />
        {maxCount > 100 &&
          <Text className={styles.maxText}>
            {textvalue ? textvalue.length : 0}{maxCount < 9999 && `/${maxCount}`}
          </Text>
        }
        {!isFocused && errorMessage && (
          <Flex className={maxCount > 100 ? styles.tipstext : styles.tipsOnetext}>
            <Icon as={require('@/assets/svg/errorIcon.svg').ReactComponent} />
            <p>{errorMessage}</p>
          </Flex>
        )}
      </Box>
      <CModal isOpen={open} onClose={() => setOpen(false)}>
        <Flex className={styles.notbalancebox}>
          <Flex className={styles.notbalanceTitleBox}>
            <Flex justifyContent={'center'} alignItems={'center'}>
              <RobotIcon />
              <Text>{parts[0]}</Text>
            </Flex>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
          </Flex>
          <Text className={styles.texttips}>{systemPromptTip}</Text>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Savebutton} onClick={() => setOpen(false)}>
              确定
            </CButton>
          </Flex>
        </Flex>
      </CModal>
    </Flex>
  );
})