import { Box, Flex, Text, Textarea } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss"

export const ComImprove: FC<any> = observer(({
  textareaRef,
  title,
  typecontent,
  handleValueChange,
  handleFocus,
  handleBlur,
  fathervalue,
  parents,
  fatherlist,
  tool
}: {
  textareaRef: any;
  title: string;
  typecontent?: any;
  handleValueChange?: (e: any) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  fathervalue?: any;
  parents?: any;
  fatherlist?: any;
  tool?: any
}) => {
  const parts = title.split('*');
  let fathervaluelist: string | any[] = [];
  if (tool) {
    fathervaluelist = tool.selects.flatMap((se: any) => {
      if (se.textvalue === parents) {
        return se.selects.map((s: any) => s.text);
      } else {
        return [];
      }
    });
  }
  const [value, setValue] = useState(' ');

  useEffect(() => {
    if (fatherlist && handleValueChange && !fathervaluelist.includes(fatherlist[parents])) {
      const newValue = '自定义';
      setValue(newValue);
    } else if (fatherlist && handleValueChange && fathervaluelist.includes(fatherlist[parents])) {
      const newValue = fatherlist[parents];
      setValue(newValue);
    }
  }, [fatherlist, parents]);
  return (
    <>
      {fatherlist && value === fathervalue && (
        <Flex className={styles.allbox}>
          <Box className={styles.titlebox}>
            {parts.map((part: any, index: any) => (
              <Text key={index} className={styles.title}>
                {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
              </Text>
            ))}
          </Box>
          <Box h={'100%'}>
            <Textarea
              className={styles.textarea}
              value={typecontent}
              onChange={handleValueChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={textareaRef}
            />
          </Box>
        </Flex>
      )}
      {fathervalue === '' && (
        <Flex className={styles.allbox}>
          <Box className={styles.titlebox}>
            {parts.map((part: any, index: any) => (
              <Text key={index} className={styles.title}>
                {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
              </Text>
            ))}
          </Box>
          <Box h={'100%'}>
            <Textarea
              className={styles.textarea}
              value={typecontent}
              onChange={handleValueChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={textareaRef}
            />
          </Box>
        </Flex>
      )}
    </>
  );
})