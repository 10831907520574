import { FC, memo, ReactNode, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Nav } from "./nav";
import { Box } from "@chakra-ui/react";
import Login from "@/pages/login";
import { globalStore } from "@/store/global";
import { observer } from "mobx-react-lite";
import { userStore } from "@/store/user";
import { Provider } from '@/pages/im/components';
import { IMContact } from "@/pages/im/contact";

import styles from './index.module.scss'
import { uiStrore } from "@/store/ui";

const Layout: FC = () => {
  const { userInfo } = userStore;
  return <Box className={styles.layout}>
    <Nav userInfo={userInfo} />
    <Box className={styles.content}>
      <IMProvider userInfo={userInfo} lang={uiStrore.language}>
        <Outlet />
        {
          userInfo && <Box display={'none'}><IMContact /></Box>
        }
      </IMProvider>
    </Box>
    {
      globalStore.loginIsOpen && <Login />
    }
  </Box>
}

interface IMProviderProps {
  children?: ReactNode
  userInfo?: UserResult
  lang?: 'zh' | 'en'
}
const IMProvider = memo((props: IMProviderProps) => {
  const { userInfo, children, lang } = props;
  console.log('locale-provider', lang)
  const im = useMemo(() => {
    return <Provider sdkVersion={1} locale={lang} initOptions={{
      appkey: '4126e53e358aa7b2777adb785602ffe5',
      account: userInfo?._id || '',
      token: userInfo?.imToken || '',
    }}>{children}
    </Provider>
    // eslint-disable-next-line
  }, [userInfo?._id, userInfo?.imToken, lang])
  return <>
    {
      userInfo ? im : children
    }
  </>
})

export default observer(Layout)