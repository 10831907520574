import { Box, Flex, Text, Icon, Image } from "@chakra-ui/react";
import { FC, useState } from "react";
import styles from './index.module.scss';
import { useTranslation } from "react-i18next";
import { uiStrore } from "@/store/ui";
import { useMessage } from "@/hooks/useMessage";
import { CButton } from "@/components/Button";
import { destoryAccount } from "@/api/personal";
import { useNavigate } from "react-router-dom";
import { userStore } from "@/store/user";
import { ipcRendererInvoke } from "@/utils/electron";
import { chatStore } from "@/store/chat";
import { creationStore } from "@/store/creation";
import { YHXY, YSXY } from "@/pages/login/LoginType";

const models = [
  {
    model: '文心大模型算法-2',
    number: '网信备案110108645502801230043'
  },
  {
    model: '达摩院交互式多能型合成算法',
    number: '网信备案330110507206401230035'
  },
  {
    model: '智谱ChatGLM生成算法',
    number: '网信备案110108105858001230019'
  }
]

export const About: FC = () => {
  const { t } = useTranslation();
  const message = useMessage();
  const { openAlert, closeAlert } = uiStrore;
  const { Logout } = userStore;
  const [ysxyOpen, setYsxyOpen] = useState(false); // 隐私协议
  const [yhxyOpen, setYhxyOpen] = useState(false); // 用户协议
  const navigate = useNavigate();
  const handleConfirm = async () => {
    const inputElement = document.getElementById("myInput") as HTMLInputElement;
    const inputValue = inputElement ? inputElement?.value : '';
    if (inputValue === '确认注销') {
      await destoryAccount();
      closeAlert();
      Logout();
      message.success({ title: '注销成功' });
      navigate('/chat');
      ipcRendererInvoke('clearCookie');
      chatStore.reset();
      creationStore.reset();
      localStorage.clear(); //清除所有缓存
    } else {
      message.error({ title: '输入错误，请重新输入 “确认注销”' });
    }
  };
  return (
    <Box className={styles.parent}>
      <Box className={styles.child}>
        <Flex className={styles.titlebox}>
          <Image className={styles.avataricon} src={require('@/assets/logo.png')} />
          <Text className={styles.avatartitle}>{t('WiseTalker')}</Text>
          <Text className={styles.avatarcontent}>{t('Version_number')}：{uiStrore.version || '0.3.8'}</Text>
        </Flex>
        <Flex className={styles.contentbox}>
          <Box className={styles.item} onClick={() => { setYhxyOpen(true) }}>
            <Text className={styles.label} >{t('WiseTalker')} {t('User_Agreement')}</Text>
            <Icon className={styles.righticon} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
          </Box>
          <Box className={styles.item} onClick={() => { setYsxyOpen(true) }}>
            <Text className={styles.label}>{t('WiseTalker')} {t('Privacy_Policy')}</Text>
            <Icon className={styles.righticon} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
          </Box>
          <Box className={styles.item} onClick={() => {
            openAlert({
              title: '模型备案公示',
              onclose: closeAlert,
              footer: <Box className={styles.recordbox}>
                <ul>
                  {models.map((item) => (
                    <li className={styles.libox}>
                      <Text><span>模型名称：</span>{item.model}</Text>
                      <Text><span>备案号：</span>{item.number}</Text>
                    </li>
                  ))}
                </ul>
              </Box>
            })
          }}>
            <Text className={styles.label}>{t('Announcement_of_the_ICP_filing_of_the_model')}</Text>
            <Icon className={styles.righticon} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
          </Box>
          <Box className={styles.item} onClick={() => {
            openAlert({
              status: 'warning',
              title: '提示',
              onclose: closeAlert,
              footer: <Box className={styles.detailbox}>
                <Text>注销后所有数据都将清除，无法找回！请在下方文本框中输入：<span>确认注销</span></Text>
                <input type="text" id="myInput" />
                <Flex className={styles.detailbox_butbox}>
                  <CButton className={styles.detailbox_calbut} onClick={closeAlert}>取消</CButton>
                  <CButton className={styles.detailbox_okbut} onClick={handleConfirm}>同意</CButton>
                </Flex>
              </Box>
            })
          }}>
            <Text className={styles.label}>{t('Account_cancellation')}</Text>
            <Icon className={styles.righticon} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
          </Box>
        </Flex>
      </Box>
      <YSXY isOpen={ysxyOpen} onClose={() => { setYsxyOpen(false) }} />
      <YHXY isOpen={yhxyOpen} onClose={() => { setYhxyOpen(false) }} />
    </Box>
  )
}