import { Box, Flex, Text, Icon, Image } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from './index.module.scss';
import { CButton } from "@/components/Button";
import { useTranslation } from "react-i18next";
import { CModal } from "@/components/Modal";
import { userStore } from "@/store/user";
import { observer } from "mobx-react-lite";
import { useMessage } from "@/hooks/useMessage";
import { uiStrore } from "@/store/ui";
import { CodeInput, PhoneInput, PwdInput } from "@/pages/login/LoginType/LoginInput";
import { globalStore } from "@/store/global";
import { WiseImage } from "@/components/Image";
import { useSelectFile } from "@/hooks/useSelectFile";
import { compressImg, getErrText } from "@/utils/file";
import { useForm } from 'react-hook-form';
import { authOpenAiKey } from "@/utils/plugin/openai";
import { useQuery } from '@tanstack/react-query';
import { queryQrcodeLogin } from "@/api/personal";
import { useNavigate } from "react-router-dom";

const avatars = [
  require('@/assets/png/avatar/avatara.png'),
  require('@/assets/png/avatar/avatarb.png'),
  require('@/assets/png/avatar/avatarc.png'),
  require('@/assets/png/avatar/avatard.png'),
  require('@/assets/png/avatar/avatare.png'),
  require('@/assets/png/avatar/avatarf.png'),
  require('@/assets/png/avatar/avatarg.png'),
  require('@/assets/png/avatar/avatarh.png'),
  require('@/assets/png/avatar/avatari.png'),
  require('@/assets/png/avatar/avatarj.png'),
]

export const PersonalData: FC = observer(() => {
  const { userInfo, qrRandKey, genQrRandKey, updateUserPhone, updateUserNickname, updateUserAvatar, codeCountdown, getCode, findPwdAction, postwxUnBind } = userStore;
  const { setLoginIsOpen } = globalStore;
  const { openAlert, closeAlert } = uiStrore;
  const [error, setError] = useState<string>('');
  const [selectAvatar, SetSelectAvatar] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [pwdAgian, setPwdAgian] = useState<string>('');
  const [pwdNotSame, setPwdNotSame] = useState<boolean>(false);
  const [showQrcode, setShowQrcode] = useState(false);
  const [pwdcodeLoading, setPwdCodeLoading] = useState<boolean>(false);
  const [codeLoading, setCodeLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isDisabled = phone === '' || pwd === '' || pwdAgian === '' || code === '';
  const message = useMessage();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [phoneOpen, setPhoneOpen] = useState<boolean>(false);
  const [nameOpen, setNameOpen] = useState<boolean>(false);
  const [avatarOpen, setAvatarOpen] = useState<boolean>(false);
  const [openRecharge, setOpenRecharge] = useState<boolean>(false);
  const userphone = userInfo?.username.slice(0, 3)
    + " **** **"
    + userInfo?.username.slice(-2)

  const sendGetCode = async () => {
    let errMsg = '';
    if (!phone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(phone)) {
      errMsg = '手机号格式错误';
    }
    if (errMsg) {
      message.error({ title: errMsg })
      return
    }

    setCodeLoading(true);
    try {
      await getCode(phone, 'bindPhone');
      setCodeLoading(false);
    } catch (err: any) {
      // console.log('返回错误', err);
      message.error({ title: err.message })
      setCodeLoading(false);
    }
  }

  const sendGetpwdCode = async () => {
    let errMsg = '';
    if (!phone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(phone)) {
      errMsg = '手机号格式错误';
    }
    if (errMsg) {
      message.error({ title: errMsg })
      return
    }

    setPwdCodeLoading(true);
    try {
      await getCode(phone, 'findPassword');
      setPwdCodeLoading(false);
    } catch (err: any) {
      // console.log('返回错误', err);
      message.error({ title: err.message })
      setPwdCodeLoading(false);
    }
  }
  const { reset } = useForm<UserUpdateParams>({
    defaultValues: userInfo as UserResult
  });
  const { File, onOpen: onOpenSelectFile } = useSelectFile({
    fileType: '.jpg,.png',
    multiple: false
  });
  const onclickSave = useCallback(
    async (data: UserUpdateParams) => {
      setLoading(true);
      try {
        if (data.openaiKey) {
          const text = await authOpenAiKey(data.openaiKey);
          text && message.warning({ title: text })
        }
        await updateUserAvatar(
          data.avatar!,
          data.openaiKey!
        );
        reset(data);
        setAvatarOpen(false)
        message.success({ title: '更新数据成功' })
      } catch (error) {
        message.error({ title: getErrText(error) })
      }
      setLoading(false);
    },
    [reset, setLoading, message, updateUserAvatar]
  );
  const onSelectFile = useCallback(
    async (e: File[]) => {
      const file = e[0];
      if (!file) return;
      try {
        const src = await compressImg({
          file,
          maxW: 100,
          maxH: 100
        });
        onclickSave({
          ...userInfo,
          avatar: src
        });
      } catch (err: any) {
        message.warning({ title: typeof err === 'string' ? err : '头像选择异常' })
      }
    },
    [onclickSave, message, userInfo]
  );
  const updatepwdconfirm = async () => {
    let errMsg = ''
    if (loading || isDisabled) return;
    if (!phone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(phone)) {
      errMsg = '手机号格式错误';
    } else if (!code) {
      errMsg = '请输入验证码';
    } else if (!pwd) {
      errMsg = '请输入密码';
    } else if (!pwdAgian) {
      errMsg = '请再次输入密码';
    } else if (pwd !== pwdAgian) {
      errMsg = '两次密码输入不一致';
    }
    if (errMsg) {
      message.error({ title: errMsg })
      return
    }

    setLoading(true);
    try {
      await findPwdAction(phone, pwd, code);
      setOpen(false)
      message.success({ title: '密码重置成功，已为您自动登录' })
      setLoginIsOpen(false);
      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      message.error({ title: (err as ErrorResponse).message })
    }
  }

  const updateAvatar = async () => {
    if (!selectAvatar) {
      message.warning({ title: '请选择头像！' })
      return;
    }
    try {
      await updateUserAvatar(selectAvatar, '');
      message.success({ title: '已成功修改头像！' })
      setAvatarOpen(false);
    } catch (err: unknown) {
      message.error({ title: (err as ErrorResponse).message })
      setAvatarOpen(false);
    }
  }

  const updatenicknamne = async () => {
    if (!nickname) {
      message.warning({ title: '请填写昵称！' })
      return;
    }
    try {
      await updateUserNickname(nickname);
      message.success({ title: '已成功修改昵称！' })
      setNameOpen(false);
    } catch (err: unknown) {
      message.error({ title: (err as ErrorResponse).message })
      setNameOpen(false);
    }
  }

  const updateBindPhone = async () => {
    if (!phone || code) {
      message.warning({ title: '请填写完整信息！' })
      return;
    }
    try {
      await updateUserPhone(phone, code);
      message.success({ title: '已成功修改绑定手机号！' })
      setPhoneOpen(false);
    } catch (err) {
      message.error({ title: '绑定错误，请联系管理员！' })
      setPhoneOpen(false);
    }
  }

  const wxUnBind = async () => {
    try {
      await postwxUnBind()
      message.success({ title: '您已成功解绑微信！' })
      closeAlert();
    } catch (err: unknown) {
      message.error({ title: (err as ErrorResponse).message })
      closeAlert();
    }
  }

  const navigate = useNavigate();
  const creatwxCode = useCallback(async () => {
    setLoading(true);
    const qrkey = genQrRandKey();
    try {
      //这里的key不需要加wxbind, 因为这个码会让手机扫，之后手机打开跳转到登陆页面， 
      //登陆页面会加 wxbind 前缀
      const urlCode =
        (window.proxyApi || 'https://www.wisetalker.cn') + '/login?pt=wxb&key=' + qrkey + '&username=' + userInfo?.username;
      new QRCode(document.getElementById('bindQRCode'), {
        text: urlCode,
        width: 128,
        height: 128,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: 2
      });
      setShowQrcode(true);
    } catch (err) {
      console.log('错误', err)
      message.error({ title: '生成错误' })
    }
    setLoading(false);
  }, [message, genQrRandKey]);

  useQuery(
    [qrRandKey, message, queryQrcodeLogin, setOpenRecharge, showQrcode],
    () => {
      if (!showQrcode) {
        return null;
      }
      return queryQrcodeLogin({ key: 'wxbind' + qrRandKey });
    },
    {
      refetchInterval: 3000,
      enabled: openRecharge,
      onSuccess(res) {
        if (!res || !res.user || !res.user.response) return;
        if (res.user.response === 'success') {
          message.success({ title: '绑定成功' });
          navigate('/personal');
        } else {
          message.error({ title: res.user.response })
        }
        setOpenRecharge(false);
      }
    }
  );

  const isPhoneNumber = (phone: any) => {
    const phoneRegex = /^1[3-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  const isBindWx = () => {
    if (userInfo) {
      const wxOpenId = userInfo.wxOpenId ? userInfo.wxOpenId : ""
      const appWxopenId = userInfo.appData ? userInfo.appData.appWxopenId : ""
      const wxUnionId = userInfo.appData ? userInfo.appData.wxUnionId : ""
      return (wxOpenId && wxOpenId.length > 0)
        || (appWxopenId && appWxopenId.length > 0)
        || (wxUnionId && wxUnionId.length > 0);
    }
    return false;
  }

  return (
    <Box className={styles.parent}>
      <Box className={styles.child}>
        <Flex className={styles.titlebox}>
          <Icon onClick={() => { setAvatarOpen(true) }} className={styles.updateavataricon} as={require(`@/assets/svg/updateAvatar.svg`).ReactComponent} />
          {/* <Image className={styles.avataricon} src={userInfo && userInfo.avatar ? userInfo.avatar : selectAvatar} /> */}
          <WiseImage onClick={() => { setAvatarOpen(true) }} className={styles.avataricon} src={userInfo ? userInfo.avatar !== '' ? userInfo.avatar : '/icon/human.png' : '/icon/human.png'} />
        </Flex>
        <Flex className={styles.contentbox}>
          <Box className={styles.item} onClick={() => {
            setNameOpen(true); setNickname((userInfo && userInfo.wiseNickName) ? userInfo?.wiseNickName : '')
          }}>
            <Text className={styles.label}>{t('nickname')}</Text>
            <Text className={styles.value}>{userInfo ? (userInfo?.wiseNickName || userInfo.nickName) : '文思用户'}</Text>
          </Box>
          <Box className={styles.item}>
            <Text className={styles.label}>{t('Mobile_phone')}</Text>
            <Flex className={styles.valuebox} onClick={() => {
              if (isPhoneNumber(userInfo?.username)) {
                openAlert({
                  title: '提示',
                  content: '你确定要更换手机号吗？',
                  onOk: () => {
                    closeAlert();
                    setPhoneOpen(true)
                  }
                })
              } else {
                setPhoneOpen(true)
              }
            }}>
              <Text className={styles.value}>
                {isPhoneNumber(userInfo?.username) ? `+86 ${userphone}` : '未绑定手机号'}
                {/* +86 {userphone} */}
              </Text>
              <Icon className={styles.righticon} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
            </Flex>
          </Box>
          <Box className={styles.item} onClick={() => {
            if (userInfo && userInfo.nickName) {
              openAlert({
                title: '提示',
                content: '你确定要解除微信绑定吗？',
                onOk: () => {
                  wxUnBind();
                }
              })
            } else {
              setOpenRecharge(true);
              setTimeout(() => { creatwxCode(); }, 1000);
            }
          }}>
            <Text className={styles.label}>{t('WeChat_name')}</Text>
            <Flex className={styles.valuebox}>
              {isBindWx() ?
                <Icon className={styles.erroricon} as={require(`@/assets/svg/updatewechat.svg`).ReactComponent} />
                : <Icon className={styles.erroricon} as={require(`@/assets/svg/nullError.svg`).ReactComponent} />}
              <Text className={styles.value} >
                {isBindWx() ? userInfo?.nickName : t('Goto_binding')}
              </Text>
              <Icon className={styles.righticon} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
            </Flex>
          </Box>
          <Box className={styles.item}>
            <Text className={styles.label}>{t('password')}</Text>
            <Flex className={styles.valuebox} onClick={() => {
              openAlert({
                title: '提示',
                content: userInfo && userInfo.ispassword === '0' ? '当前暂未设置密码，确定设置密码吗？' : '您当前已设置密码，确定需要重置密码吗？',
                onOk: () => {
                  closeAlert();
                  setOpen(true)
                }
              })
            }}>
              {userInfo && userInfo.ispassword === '0' &&
                <Icon className={styles.erroricon} as={require(`@/assets/svg/nullError.svg`).ReactComponent} />}
              <Text className={styles.value}>
                {userInfo && userInfo.ispassword === '0' ? t('Goto_Settings') : '已设置'}
              </Text>
              <Icon className={styles.righticon} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
            </Flex>
          </Box>
        </Flex>
        {/* <Flex>
          <CButton className={styles.cbutton}>
            保存
          </CButton>
        </Flex> */}
      </Box>
      {/* 更换密码 */}
      <CModal isOpen={open} onClose={() => setOpen(false)}>
        <Flex className={styles.cmodelbox}>
          <Flex className={styles.cmodelTitleBox}>
            <Text>设置密码</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
          </Flex>
          <PhoneInput err={error} onChange={value => {
            setPhone(value);
            if (error) {
              setError('');
            }
          }} />
          <CodeInput loading={pwdcodeLoading} cutdown={codeCountdown} onChange={setCode} onGetCode={sendGetpwdCode} />
          <PwdInput
            placeholder={'请输入密码'}
            onChange={value => {
              setPwd(value);
              if (value && pwdAgian && value !== pwdAgian) {
                setPwdNotSame(true);
              } else {
                setPwdNotSame(false);
              }
            }}
          />
          <PwdInput
            onChange={value => {
              setPwdAgian(value);
              if (value && pwd && value !== pwd) {
                setPwdNotSame(true);
              } else {
                setPwdNotSame(false);
              }
            }}
            placeholder={'请再次输入密码'}
          />
          {
            pwdNotSame && <Box className={styles.pwdNotSame}>
              <Icon as={require('@/assets/svg/pwd_error.svg').ReactComponent} />
              <Text className={styles.text}>两次密码输入不一致</Text>
            </Box>
          }
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Cancelbutton} onClick={() => setOpen(false)} >
              取消
            </CButton>
            <CButton className={styles.Savebutton} onClick={updatepwdconfirm}>
              确认
            </CButton>
          </Flex>
        </Flex>
      </CModal>
      {/* 更换手机号 */}
      <CModal isOpen={phoneOpen} onClose={() => setPhoneOpen(false)}>
        <Flex className={styles.cmodelbox}>
          <Flex className={styles.cmodelTitleBox}>
            <Text>{isPhoneNumber(userInfo?.username) ? '更换手机号' : '绑定手机号'}</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setPhoneOpen(false)} />
          </Flex>
          <PhoneInput err={error} onChange={value => {
            setPhone(value);
            if (error) {
              setError('');
            }
          }} />
          <CodeInput loading={codeLoading} cutdown={codeCountdown} onChange={setCode} onGetCode={sendGetCode} />
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Cancelbutton} onClick={() => setPhoneOpen(false)} >
              取消
            </CButton>
            <CButton className={styles.Savebutton} onClick={updateBindPhone}>
              确认
            </CButton>
          </Flex>
        </Flex>
      </CModal>
      {/* 更换昵称 */}
      <CModal isOpen={nameOpen} onClose={() => setNameOpen(false)}>
        <Flex className={styles.cmodelbox}>
          <Flex className={styles.cmodelTitleBox}>
            <Text>修改昵称</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setNameOpen(false)} />
          </Flex>
          <Flex className={styles.input_group}>
            <input maxLength={15} type="text" value={nickname} className={styles.input_field}
              placeholder="请输入昵称" onChange={value => {
                setNickname(value.target.value);
              }} />
            {nickname && nickname.length > 0
              ? <Icon className={styles.input_but} as={require('@/assets/svg/toolsEmpty.svg').ReactComponent} onClick={() => setNickname('')} />
              : <Text className={styles.input_count}>{nickname.length}/15</Text>
            }
          </Flex>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Cancelbutton} onClick={() => setNameOpen(false)} >
              取消
            </CButton>
            <CButton isDisabled={!nickname} className={styles.Savebutton} onClick={updatenicknamne}>
              确认
            </CButton>
          </Flex>
        </Flex>
      </CModal>
      {/* 更换头像 */}
      <CModal isOpen={avatarOpen} onClose={() => setAvatarOpen(false)}>
        <Flex className={styles.avatarcmodelbox}>
          <Flex className={styles.cmodelTitleBox}>
            <Text>选择头像</Text>
            <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => { SetSelectAvatar(''); setAvatarOpen(false) }} />
          </Flex>
          <Flex className={styles.avatar_group}>
            {avatars.map((item, index) => (
              <div className={styles.avatar_box} key={index} onClick={() => SetSelectAvatar(item)}>
                {selectAvatar === item && (
                  <Flex className={styles.avatar_boxSelected}>
                    <Icon as={require('@/assets/svg/selectAvatar.svg').ReactComponent} />
                  </Flex>
                )}
                <img src={item} alt={`avatar-${index}`} />
              </div>
            ))}
            <div className={styles.avatar_box} onClick={onOpenSelectFile}>
              <Icon as={require('@/assets/svg/avatarUpdate.svg').ReactComponent} />
            </div>
          </Flex>
          <Flex className={styles.modelbuttonbox}>
            <CButton className={styles.Cancelbutton} onClick={() => { SetSelectAvatar(''); setAvatarOpen(false) }} >
              取消
            </CButton>
            <CButton className={styles.Savebutton} onClick={updateAvatar}>
              确认
            </CButton>
          </Flex>
        </Flex>
      </CModal>
      <CModal isOpen={openRecharge} onClose={() => setOpenRecharge(false)}>
        <Flex className={styles.cmodelpaybox}>
          <Flex className={styles.cmodelTitleBox}>
            <Text>绑定微信</Text>
            <Icon onClick={() => setOpenRecharge(false)} as={require(`@/assets/svg/cModelClose.svg`).ReactComponent} />
          </Flex>
          <Flex className={styles.payimgbox} >
            <Box id="bindQRCode" className={styles.payimage} display="inline-block"></Box>
            {/* <Image className={styles.payimage} src={'https://cachefile.wisetalker.cn/file/config/gzh.jpeg'} /> */}
            <Text>请打开手机微信扫码</Text>
            <Text>请勿关闭页面</Text>
          </Flex>
        </Flex>
      </CModal>
      <File onSelect={onSelectFile} />
    </Box>
  )
})