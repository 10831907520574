import { delChatHistoryById, fetchAgents, fetchChatInfo, fetchChatModels, fetchCreatAgents, fetchDelAgents, fetchHistorys, fetchMineAgents, fetchUpdateAgents, getPluginChatInfo, getSupportFuncs, topChatHistoryById } from "@/api/chat"
import { chatDb } from "@/db/chat";
import { _AI_DefaultModel } from "@/utils/common";
import dayjs from "dayjs";
import { makeAutoObservable } from "mobx"
import { makePersistable } from "mobx-persist-store";

export enum ChatType {
  AI = 'AI',
  Plugin = 'Plugin'
}

export enum ChatRoleEnum {
  System = 'System',
  Human = 'Human',
  AI = 'AI'
}

const randomColor = ['#E16BFF', '#7D82FF', '#FF9E75', '#5CD78D', '#FCD503', '#666666', '#FF6F6A'];

export const defaultChatData: AIChatInfoResult = {
  chatId: '',
  modelId: '',
  model: {
    name: '',
    avatar: '/icon/logo.png',
    intro: '',
    canUse: false
  },
  chatModel: _AI_DefaultModel,
  temperature: 1, //模型温度
  maxToken: 2000, //模型最大token数
  history: [],
  total: 0
};

class ChatStore {
  histories: UseHistoryObject = {};
  currentExpand: number = -1;
  currentChat?: CurrentChat; // 当前选择的聊天 存储在localstorage
  aiChatInfo: Map<string | 'newChatId', AIChatInfoResult> = new Map(); // 当前所有聊天具体内容 存储在本地数据库 key: value
  currentType: `${ChatType}` = ChatType.AI; // 当前聊天类型
  isHideHistoryNav: boolean = false; // 是否隐藏历史记录
  models: Record<string, ModelsForGroup> = {};
  mediaModels: ResultModels[] = [];
  chooseModel?: ResultModels; // 选择的模型
  TextareaValue: string = '';
  agents: AgentsType[] = [];
  mineAgents: MineAgentsType = { myCollectionModels: [], myModels: [] };
  modelInfo: Record<string, { avatar: string, name: string, price: number }> = {};
  generateMsg: Map<string | 'newChatId', GeneratingMsg | undefined> = new Map(); // 生成中的消息
  ohterStartQuestion: string = ''; // 其他地方触发问题 例如点击示例开始对话
  quote?: QuoteType; // 引用的消息
  needReRender: boolean = false; // 是否需要重新渲染 用于触发子组件重新渲染 例如发送最新消息
  playingId: string = ''; // 当前播放的QA id
  videoSummaryChat: any = { ...defaultChatData, chatId: '' };
  // 多模态相关
  supportFuncs: { title: string, svg: string }[] = [];
  pluginChatInfo: Map<string | 'newChatId', PluginChatInfo> = new Map(); // 当前所有聊天具体内容 存储在本地数据库 key: value
  generatePluginMsg: Map<string | 'newChatId', GeneratingPluginMsg | undefined> = new Map(); // 生成中的消息
  agentLine: number = 3;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'chatStore',
      properties: ['currentExpand', 'currentChat', 'currentType', 'models', 'chooseModel', 'TextareaValue', 'modelInfo'],
      storage: window.localStorage
    })

    this.aiChatInfo.set('newChatId', defaultChatData)
  }

  init() {
    console.log('init')
    this.getModels();
    this.getAgents();
  }

  reset() {
    this.histories = {};
    this.currentExpand = -1;
    this.currentChat = undefined;
    this.aiChatInfo.clear();
    this.currentType = ChatType.AI;
    this.isHideHistoryNav = false;
    this.models = {};
    this.mediaModels = [];
    this.chooseModel = undefined;
    this.TextareaValue = '';
    this.agents = [];
    this.mineAgents = { myCollectionModels: [], myModels: [] };
    this.modelInfo = {};
    this.generateMsg.clear();
    this.ohterStartQuestion = '';
    this.quote = undefined;
    this.needReRender = false;
    this.playingId = '';
    this.videoSummaryChat = { ...defaultChatData, chatId: '' };
    this.supportFuncs = [];
    this.pluginChatInfo.clear();
    this.generatePluginMsg.clear();
  }

  async getHistories() {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      chatDb.getHistories().then(res => {
        // 防止http请求先获取到数据
        if (!this.histories.length && res) {
          this.setHitories(res)
        }
      })

      // 请求服务端历史记录并同步至本地
      const result = await fetchHistorys({ pageNum: 1, pageSize: 10 });
      const useHistories = mergeHistories(result);
      this.setHitories(useHistories, true);
      return useHistories;
    } catch (err) {
      console.log(err)
    }
  }

  setHitories(histories: UseHistoryObject, writeDb = false) {
    this.histories = histories;
    writeDb && chatDb.setHistories(histories)
  }

  setVideoSummaryChat(e: any | ((e: any) => any) = defaultChatData) {
    if (typeof e === 'function') {
      // set((state) => {
      //   state.videoSummaryChat = e(state.videoSummaryChat);
      // });
      if (this.videoSummaryChat) {
        this.videoSummaryChat = e(this.videoSummaryChat);
      }
    } else {
      // set((state) => {
      //   state.videoSummaryChat = e;
      // });
      if (this.videoSummaryChat) {
        this.videoSummaryChat = e;
      }
    }
  }

  async getChatInfo() {
    if (!this.currentChat) return;

    const { modelId, chatId, isPlugin } = this.currentChat;
    let isGeted = false;
    let len = 0;
    let isRisk = false;
    if (isPlugin) {
      this.setChatType(ChatType.Plugin);
      try {
        chatDb.getPluginChatInfo(chatId).then(res => {
          if (!isGeted && res) {
            this.setPluginChatInfo(res);
            len = Object.keys(res.mapping).length;
          }
        })
        const res = await getPluginChatInfo(chatId);
        isGeted = true;

        // 如果历史记录有更新则重新渲染
        if (Object.keys(res.mapping).length !== len && this.generateMsg.get(chatId)?.status !== 'loading') {
          this.setPluginChatInfo(res, true);
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        this.setChatType(ChatType.AI);
        chatDb.getAIChatInfo(chatId).then(res => {
          if (!isGeted && res) {
            this.setChatInfo({ info: res, chatId });
            len = res.history ? res.history.length : 0;
            isRisk = res.isRisk || false;
          }
        })
        const res = await fetchChatInfo({ modelId, chatId });
        isGeted = true;
        const shouldUpdate = res.history.length !== len && this.generateMsg.get(chatId)?.status !== 'loading';
        // 如果历史记录有更新则重新渲染
        if (shouldUpdate || (res.isRisk && !isRisk)) {
          this.setChatInfo({ info: res, chatId }, true);
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  setChatInfoAsFunction(data: SetChatInfoAsFunctionParams, writeDb = false) {
    const { info, chatId } = data;
    const _chatId = chatId || 'newChatId';
    const _current = this.aiChatInfo.get(_chatId);
    const newList = info(_current || { history: [] })
    this.aiChatInfo.set(_chatId, newList)
    writeDb && chatId && chatDb.setAIChatInfo(JSON.parse(JSON.stringify(newList)))
  }

  setChatInfo(data: SetChatInfoParams, writeDb = false) {
    const { info, chatId } = data;
    this.aiChatInfo.set(chatId || 'newChatId', info);
    this.setNeedReRender();
    writeDb && info.chatId && chatDb.setAIChatInfo(info)
  }

  resetDefaultChatInfo() {
    this.aiChatInfo.set('newChatId', defaultChatData)
  }

  /** 获取模型列表 */
  async getModels() {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      chatDb.getModels().then(res => {
        // 防止http请求先获取到数据
        try {
          const keys = Object.keys(this.models)
          if (!keys.length && res) {
            this.setModels(res)
            if (!this.chooseModel) { // 默认选择第一个模型
              this.setChooseModel(res[Object.keys(res)[0]].models[0]);
            }
          }
        } catch (err) {
          console.log('本地数据库设置模型失败')
        }
      })

      // 请求服务端模型列表并同步至本地
      const res = await fetchChatModels();
      const { groupModel, modelsInfo, mediaModels } = mergeModels(res);
      this.setModelInfo(modelsInfo);
      this.setModels(groupModel, true)
      this.setMediaModels(mediaModels)
      if (!this.chooseModel) {
        this.setChooseModel(res[0]);
      }
    } catch (err) {
      console.log(err)
    }
  }

  /** 获取智能体 */
  async getAgents() {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      chatDb.getAgents().then(res => {
        // 防止http请求先获取到数据
        if (!this.agents.length && res) {
          this.setAgents(res.agents, res.mineAgents)
        }
      })
      const res = await fetchAgents({ pageNum: 1, pageSize: 999 });
      const mineRes = await fetchMineAgents()
      this.setAgents(res.data, mineRes, true)
    } catch (err) {
      console.log(err)
    }
  }

  setAgents(agents: AgentsType[], mineAgents: MineAgentsType, writeDb = false) {
    this.agents = agents;
    this.mineAgents = mineAgents;
    writeDb && chatDb.setAgents(agents, mineAgents)
  }

  /** 删除对话 */
  async deleteChatAction(ids: string[]) {
    await delChatHistoryById(ids)
  }

  /** 创建新智能体 */
  async createNewAgent() {
    await fetchCreatAgents({ name: '我的应用' })
    this.getAgents();
  }

  /** 删除智能体 */
  async deleteAgent(id: string) {
    await fetchDelAgents(id)
    this.getAgents();
  }

  /** 修改智能体 */
  async updateAgent(id: string, data: AgentsType) {
    await fetchUpdateAgents(id, data)
    this.getAgents();
  }

  async changeChatHistoryAction(id: string, top: boolean, title?: string) {
    await topChatHistoryById(id, top, title)
  }

  async getPluginSupportFuncs() {
    try {
      let isGeted = false;
      chatDb.getSupportFuncs().then(res => {
        if (!isGeted && res) {
          this.setSupportFuncs(res);
        }
      })
      const res = await getSupportFuncs();
      isGeted = true;
      this.setSupportFuncs(res, true);
    } catch (err) {
      console.log(err)
    }
  }

  setSupportFuncs(funcs: { title: string, svg: string }[], writeDb = false) {
    this.supportFuncs = funcs;
    writeDb && chatDb.setSupportFuncs(funcs)
  }

  /**当前展开的chat */
  setCurrentExpand(expand: number) {
    this.currentExpand = expand;
  }

  /**当前点开的对话详情内页 */
  setCurrentChat(chat?: CurrentChat) {
    this.currentChat = chat;
  }

  /** 当前对话类型 */
  setChatType(type: ChatType) {
    this.currentType = type;
  }

  setIsHideHistoryNav(isHide: boolean) {
    this.isHideHistoryNav = isHide;
  }

  setModelInfo(info: Record<string, { avatar: string, name: string, price: number }>) {
    this.modelInfo = info;
  }

  setGenerateMsg(chatId?: string, generating?: GeneratingMsg | ((generating?: GeneratingMsg) => GeneratingMsg | undefined)) {
    if (typeof generating === 'function') {
      const _id = chatId || 'newChatId';
      this.generateMsg?.get(_id) && (this.generateMsg.set(_id, generating(this.generateMsg.get(_id))));
    } else {
      this.generateMsg.set(chatId || 'newChatId', generating);
    }
  }

  setGeneratePluginMsg(id?: string, generating?: GeneratingPluginMsg | ((generating?: GeneratingPluginMsg) => GeneratingPluginMsg | undefined)) {
    if (typeof generating === 'function') {
      const _id = id || 'newChatId';
      const info = this.generatePluginMsg.get(_id);
      info && (this.generatePluginMsg.set(_id, generating(info)));
    } else {
      this.generatePluginMsg.set(id || 'newChatId', generating);
    }
  }

  setModels(models: Record<string, ModelsForGroup>, writeDb = false) {
    this.models = models;
    writeDb && chatDb.setModels(models)
  }

  setMediaModels(models: ResultModels[]) {
    this.mediaModels = models;
  }

  setChooseModel(model: ResultModels) {
    this.chooseModel = model;
  }

  setTextareaValue(value: string) {
    this.TextareaValue = value;
  }

  setOtherStartQuestion(value: string) {
    this.ohterStartQuestion = value;
  }

  setQuote(quote?: QuoteType) {
    this.quote = quote;
  }

  setNeedReRender() {
    this.needReRender = !this.needReRender;
  }

  setPlayingId(id: string) {
    this.playingId = id;
  }

  setPluginChatInfoAsFunction(data: SetPluginChatInfoasFunctionParams, writeDb = false) {
    const { info, conversation_id } = data;
    const _chatId = conversation_id || 'newChatId';
    const _current = this.pluginChatInfo.get(_chatId);
    if (!_current) return;
    const newList = info(_current)
    this.pluginChatInfo.set(_chatId, newList)
    writeDb && _chatId && chatDb.setPluginChatInfo(JSON.parse(JSON.stringify(newList)))
  }

  setPluginChatInfo(data: PluginChatInfo, writeDb = false) {
    const { conversation_id } = data;
    this.pluginChatInfo.set(conversation_id || 'newChatId', data);
    this.setNeedReRender();
    writeDb && conversation_id && chatDb.setPluginChatInfo(data)
  }

  setAgentLine(line: number) {
    this.agentLine = line <= 1 ? 1 : (line >= 3 ? 3 : line);
  }
}

// 合并成符合ui的历史记录数据结构
const mergeHistories = (histories: ResultHistoryItem[]): UseHistoryObject => {
  let _arr: UseHistoryObject = {};
  histories.forEach(item => {
    const color = randomColor[dayjs(item.updateTime).get('day')]
    const dayType: 'today' | 'yesterday' | 'before' = dayjs(item.updateTime).isSame(dayjs(), 'day') ? 'today' : dayjs(item.updateTime).isSame(dayjs().subtract(1, 'day'), 'day') ? 'yesterday' : 'before';
    if (item.isPlugin) {
      if (_arr['多模态对话']) {
        _arr['多模态对话'].children.push({ ...item, color, dayType })
      } else {
        _arr['多模态对话'] = {
          title: '多模态对话',
          modelId: item.modelId,
          isPlugin: true,
          children: [{ ...item, color, dayType }]
        }
      }
    } else {
      if (_arr[item.modelId]) {
        _arr[item.modelId].children.push({ ...item, color, dayType })
      } else {
        _arr[item.modelId] = {
          title: item.modelName,
          modelId: item.modelId,
          isPlugin: false,
          avatar: item.avatar,
          children: [{ ...item, color, dayType }]
        }
      }
    }
  })
  return _arr
}

const mediaModelList = ['gpt-4o']
/**
 * 合并模型数据结构
 */
const mergeModels = (models: ResultModels[]) => {
  let groupModel: Record<string, ModelsForGroup> = {};
  let mediaModels: ResultModels[] = [];
  const modelsInfo: Record<string, { avatar: string, name: string, price: number }> = {}
  models.forEach(model => {
    modelsInfo[model.key] = {
      avatar: model.expand.group.avatar,
      name: model.name,
      price: model.params.single_price || 0
    };
    if (mediaModelList.includes(model.key)) {
      mediaModels.push(model)
    }
    if (groupModel[model.expand.group.name]) {
      groupModel[model.expand.group.name].models.push(model)
    } else {
      groupModel[model.expand.group.name] = {
        name: model.expand.group.name,
        avatar: model.expand.group.avatar,
        models: [model]
      }
    }
  })
  return { groupModel, modelsInfo, mediaModels }
}

export const chatStore = new ChatStore();