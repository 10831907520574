import { Box, Text, Textarea } from "@chakra-ui/react";
import { FC } from "react";
import { CreationTitle, CreationTitleBox } from "../";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss'

interface CreationPromptProps {
  cnPrompt: string
  onInput: (value: string) => void
}
export const CreationSongName: FC<CreationPromptProps> = (props) => {
  const { cnPrompt, onInput } = props;
  const { t } = useTranslation();
  return <Box className={styles.prompt}>
    <Box className={styles.title}>
      <CreationTitleBox className={styles.text}>
        <CreationTitle title={t('creation.SongName')} />
        <Text className={styles.require}>*</Text>
      </CreationTitleBox>
    </Box>
    <Box className={styles.inputs}>
      <Textarea value={cnPrompt} className={styles.area} resize='none' maxLength={500} placeholder={t('PlsInputPrompt')} onChange={e => onInput(e.target.value)} />
    </Box>
  </Box>
}
