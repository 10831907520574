import { ToolhistorySchema, ToolSchema, VideoSummaryModelSchema } from "@/@types/tools";
import { fetchAllTools, fetchAllToolsHistory, fetchCreateToolHistory, fetchcreateVideoSummary, fetchCreatTranslations, fetchDeleteToolsHistory, fetchDeleteTranslations, fetchGetToolByLink, fetchGetTranslations, fetchVideoSummaryList, getShareModelsForGroup } from "@/api/tools";
import { toolDb } from "@/db/tool";
import { makeAutoObservable } from "mobx"

export enum LanguageEnum {
  CN = '中文(Chinese)',
  EN = '英语(English)',
  FR = '法语(Français)',
  DE = '德语(Deutsch)',
  JP = '日语(日本語)',
  KO = '韩语(한국어)',
  ES = '西班牙语(Español)',
  PY = '俄语(Русский)',
  AR = '阿拉伯语(العربية)',
  PO = '葡萄牙语(Português)',
  ITAli = '意大利语(Italiano)',
  GR = '希腊语(Ελληνικά)',
  NED = '荷兰语(Nederlands)',
  POL = '波兰语(Polski)',
  BUL = '保加利亚语(български)',
  EES = '爱沙尼亚语(eesti)',
  DAN = '丹麦语(Dansk)',
  SUO = '芬兰语(Suomi)',
  CZE = '捷克语(Čeština)',
  ROM = '罗马尼亚语(Română)',
  SLOVEN = '斯洛文尼亚语(Slovenščina)',
  SVEN = '瑞典语(Svenska)',
  VIE = '越南语(Tiếng Việt)',
  THAI = '泰语(ภาษาไทย)',
  SHQIP = '阿尔巴尼亚语 (Shqip)',
  FILI = '菲律宾语 (Filipino)',
  TUR = '土耳其语 (Türkçe)',
  MAG = '匈牙利语 (Magyar)',
  NOR = '挪威语 (Norsk)',
  SLOVA = '斯洛伐克语 (Slovenčina)',
  MELA = '马来语 (Bahasa Melayu)',
  HEB = '希伯来语 (עברית)'
}

export const languageArray = [
  { text: '中文(Chinese)', value: 'CN', lang_key: 'chi_sim' },
  { text: '英语(English)', value: 'EN', lang_key: 'eng' },
  { text: '法语(Français)', value: 'FR', lang_key: 'fra' },
  { text: '德语(Deutsch)', value: 'DE', lang_key: 'deu' },
  { text: '日语(日本語)', value: 'JP', lang_key: 'jpn' },
  { text: '韩语(한국어)', value: 'KO', lang_key: 'kor' },
  { text: '西班牙语(Español)', value: 'ES', lang_key: 'spa' },
  { text: '俄语(Русский)', value: 'PY', lang_key: 'rus' },
  { text: '阿拉伯语(العربية)', value: 'AR', lang_key: 'ara' },
  { text: '葡萄牙语(Português)', value: 'PO', lang_key: 'por' },
  { text: '意大利语(Italiano)', value: 'ITAli', lang_key: 'ita' },
];

export enum VideoSummaryStatusEnum {
  WAIT = 0,
  PROCESSING = 1,
  PROCESSING13 = 13,
  PROCESSING14 = 14,
  COMPLETED = 2,
  FAIL = 3,
}

export enum HdrestorationUpStatusEnum {
  Run_UPLoadToOSS_Completed = 8, //上传到OSS完成
  WAIT = 0,
  PROCESSING = 1,
  COMPLETED = 2,
  FAIL = 3,
  DELETE = 9,
}

class ToolStore {
  tools: ToolSchema[] = [];
  allToolsHistory: ToolhistorySchema[] = [];
  videoSummaryList: VideoSummaryModelSchema[] = [];
  toolModels: any[] = [];
  showNav = true;
  toolShowNav = true;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async getToollist() {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      toolDb.getTools().then(res => {
        // 防止http请求先获取到数据
        if (res && !this.tools.length) {
          this.setToollist(res)
        }
      })

      // 请求服务端历史记录并同步至本地
      const result = await fetchAllTools();
      const filteredResult = result.filter(tool => {
        if (!tool.assembly) return false;
        const { temperature, link } = tool.assembly;
        // 增加展示特定的工具
        if (link === 'filmtranslations' || link === 'langtranslations'
          || link === 'videoSummary' || link === 'writingAssistant'
          || link === 'swdt' || link === 'wechatRobot' || link === 'tarotDivination' || link === 'naMing')
          return true;
        // 筛选通用数据
        if (temperature && temperature === '99') return false;
        return true;
      });
      this.setToollist(filteredResult);
    } catch (err) {
      console.log(err)
    }
  }

  setToollist(tools: ToolSchema[]) {
    this.tools = tools;
    toolDb.setTools(tools)
  }

  async getToolModel(toolname: string) {
    let getmodels = this.toolModels
    if (getmodels.length <= 0) {
      const shareRes = await getShareModelsForGroup('工具')
      this.toolModels = shareRes.models;
      getmodels = shareRes.models;
    }
    const findRet = getmodels.find((ii) => ii.linkTool == toolname)?._id
    if (findRet == undefined) {
      return ''
    }
    return findRet;
  }

  async createToolHistory({
    toolsid,
    newChatId,
    toolsnanme,
    userinput,
    improve,
    contents,
    model,
    template
  }: {
    toolsid: string,
    newChatId: string,
    toolsnanme: string,
    userinput: string,
    improve: string,
    contents: string,
    model: any,
    template: string,
  }) {
    const res = await fetchCreateToolHistory({
      toolsid,
      newChatId,
      toolsnanme,
      userinput,
      improve,
      contents,
      model,
      template
    })
    return res;
  }

  async getAllToolsHistory({ id }: { id: string }) {
    try {
      // 从未获取过历史记录去本地数据库中取一次
      toolDb.getAllToolsHistory().then(res => {
        // 防止http请求先获取到数据
        if (res && !this.allToolsHistory.length) {
          this.setAllToolsHistory(res)
        }
      })

      // 请求服务端历史记录并同步至本地
      const result = await fetchAllToolsHistory({ _id: id });
      this.setAllToolsHistory(result);
    } catch (err) {
      console.log(err)
    }
  }

  setAllToolsHistory(allToolsHistory: ToolhistorySchema[]) {
    this.allToolsHistory = allToolsHistory;
    toolDb.setAllToolsHistory(allToolsHistory)
  }

  async deleteToolsHistory({ id }: { id: string }) {
    const res = await fetchDeleteToolsHistory({ _id: id });
    return res;
  }

  async postCreatTranslations({
    language,
    title,
    filename,
    type,
    model,
    des_subtitle_type,
    level,
    template,
    tooltype,
    lang_key,
    des_lange
  }: {
    language: string;
    title: string;
    filename: string;
    type: string;
    model: string;
    des_subtitle_type: number;
    level: string;
    template: string;
    tooltype: string;
    lang_key: string;
    des_lange: string;
  }) {
    const res = await fetchCreatTranslations({
      language,
      title,
      filename,
      type,
      model,
      des_subtitle_type,
      level,
      template,
      tooltype,
      lang_key,
      des_lange
    });
    return res;
  }

  async getTranslations({ types }: { types: string }) {
    const res = await fetchGetTranslations({ types });
    return res;
  }

  async getToolByLink({ toollink }: { toollink: string }) {
    const res = await fetchGetToolByLink({ toollink });
    return res;
  }

  async deleteTranslations({ _id, types }: { _id: string, types: string }) {
    const res = await fetchDeleteTranslations({
      _id,
      types
    });
    return res;
  }

  /** 获取翻译历史记录 */
  async getVideoSummaryList() {
    const result = await fetchVideoSummaryList();
    return result;
  }

  async createVideoSummary(url: string, toolid: string, model: string) {
    const res = await fetchcreateVideoSummary(url, toolid, model);
    return res;
  }

  setShowNav(showNav: boolean) {
    this.showNav = showNav;
  }

  setToolShowNav(toolShowNav: boolean) {
    this.toolShowNav = toolShowNav;
  }
}

export const toolStore = new ToolStore();