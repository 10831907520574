import { observer } from "mobx-react-lite";
import { Box, Flex, useDisclosure, Text, Icon, Textarea, Image } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from './index.module.scss'
import { ComTitle } from "../components/ComTitle";
import { ScrollBarBox } from "@/components/ScrollBox";
import { ComButton } from "../components/ComButton";
import { Contentpreview } from "../components/ContentPreview";
import { TarotSchema, ToolSchema } from "@/@types/tools";
import { toolStore } from "@/store/tool";
import { throttle } from "lodash";
import { useMessage } from "@/hooks/useMessage";
import { streamFetch } from "@/utils/stream";
import { deleteTarot, getTarot, gettoolhis, postCreateTarot } from "@/api/tools";
import { _Models } from "..";
import { userStore } from "@/store/user";

const InitValues = [
  {
    Problem: '近期爱情运势',
    RandomImage: {
      lastrandom: { name: '金币王', tag: 'jbw' },
      nowrandom: { name: '金币五', tag: 'jb5' },
      futurerandom: { name: '女祭司', tag: 'njs' }
    },
    Content: `在塔罗牌中，每张牌都代表着特定的情绪、事件或者生命阶段。根据你抽到的三张牌，我们可以分析你的近期爱情运势：

1. 过去的牌 - 金币王 (King of Pentacles)
金币王代表财务稳定、实际与成功。在爱情中，这张牌可能象征着一段稳定、舒适的时期，你可能与伴侣共同建立了物质基础，安全感和信任。在感情的发展中，这也可能表示你或你的伴侣很擅长处理实际问题，财务稳定对于这段关系来说是一个重要的支撑点。

2. 现在的牌 - 金币五 (Five of Pentacles)
金币五通常暗示经济上或情感上的困难。可能是经济上的损失，或者感到被排斥、孤立。放在爱情运势上来看，它可能意味着你目前感到在感情中有些缺乏，比如感到不被关注、支持不足，或者在物质方面有所忧虑。这张牌鼓励你寻找帮助，与伴侣沟通，共同克服困难。

3. 未来的牌 - 女祭司 (The High Priestess)
女祭司通常和直觉、神秘、潜在的事务有关。在爱情塔罗牌中，这张牌提醒你要倾听你的内心、直觉和你的潜意识。未来可能会有一些爱情的秘密或未知因素显露出来，需要你的智慧和直觉去感应。这张牌鼓励你保持耐心，相信自己内在的智慧能带领你走向充满爱的未来。

要结合这些信息给你的爱情运势做一个整体的解释：

在不久前，你可能享受了一段稳定的关系，或者至少感觉在经济和物质层面相对安稳。然而，目前你可能正遭遇一些挑战，感到在情感或物质层面不太满足，觉得需要更多的支持和关爱。接下来的时期，你将被引导去更多地倾听自己的直觉，并可能需要面对一些深藏的真相或面临决策。女祭司呼吁你相信自己的内在力量，同时也暗示了未来的爱情中可能会有更多的精神层面的连接和成长。

记得塔罗牌不是决定命运的工具，而是一面镜子，反映当前的情况和可能的趋势。真正的答案和选择权始终掌握在你自己手中，通过自我反省、与伴侣的沟通和共同努力去改善你的爱情生活。`
  },
  {
    Problem: '近期工作运势',
    RandomImage: {
      lastrandom: { name: '恶魔', tag: 'em' },
      nowrandom: { name: '圣杯七', tag: 'sb7' },
      futurerandom: { name: '宝剑皇后', tag: 'bjhh' }
    },
    Content: `在塔罗牌阅读中，每张牌的含义可以根据它出现的位置（过去、现在、未来）和问卷的具体问题（此处为工作运势）来解释。以下是针对你提供的卡牌的解释，以及如何将它们联系起来以回答你的问题。

1. 过去的牌 - 恶魔：在塔罗牌里，恶魔通常代表陷阱、依赖或是不健康的行为模式。在工作环境中，这可能意味着过去你可能感觉到了某种程度上的束缚——可能是一份不令人满意的工作，或者是公司文化中某些不良的影响。这张牌提醒你曾被一些可能对个人成长和发展不利的因素所限制。

2. 现在的牌 - 圣杯七：圣杯七象征选择和决策。在你的工作运势中，这可能表示目前你处于一个决策的十字路口，你可能有不同的职业道路可以选择，或者是在考虑不同的工作或项目方向。这张牌鼓励你做出深思熟虑的选择，并考虑长期的情感满足和职业愿景。

3. 未来的牌 - 宝剑皇后：宝剑皇后代表智慧、清晰的思维和直言不讳。在工作运势的读数上，这表明你未来会更加重视逻辑和分析，也可能意味着你会采取更加理性和策略性的方法来应对即将到来的职业挑战。宝剑皇后鼓励公正、清晰的沟通和为自己确立边界。

结合三张牌，你的工作未来似乎将经历一段转变。从可能的困境或束缚（恶魔）到现在需要做出某些关键选择（圣杯七），以及未来以更加清晰和智慧的方式向前迈进（宝剑皇后），你似乎正走在一条成长和自我提升的职业道路上。

建议你以清晰和策略性的思维来处理当前的选择和决策，并为未来做好准备。尽量保持客观和有洞察力，这样可以帮助你在工作上达成成功并避免以往可能遇到的陷阱。总之，虽然挑战不可避免，但你有机会利用你的智慧和沟通能力来提升你的工作运势。`
  },
  {
    Problem: '近期财运',
    RandomImage: {
      lastrandom: { name: '宝剑六', tag: 'bj6' },
      nowrandom: { name: '宝剑一', tag: 'bj1' },
      futurerandom: { name: '皇帝', tag: 'hd' }
    },
    Content: `你好，在塔罗牌阅读中，每张牌象征着不同的意义，同时也反映了过去、现在和未来的情境。下面将为你解释你抽到的塔罗牌，并且结合你所问的近期财运做一个解读。
1. 过去的牌是：宝剑六（Six of Swords）
宝剑六通常代表逃避、旅行或者是逐渐脱离困境。在财务方面，这可能意味着你可能已经从以前的一些经济困境中慢慢走了出来，或者已经做出了一些决定来改变你的财务状况，例如改变投资策略、换工作或者摆脱债务。
2. 现在的牌是：宝剑一（Ace of Swords）
宝剑一象征新的开始、清晰的认识和决断。这张牌暗示你目前可能会有一个新的财务机会或者是灵感，要求你保持清醒的头脑，采取果断的行动。这对你的经济状况是一个积极的信号，要利用这个时期来作出明智的决策和投资。
3. 未来的牌是：皇帝（The Emperor）
皇帝代表稳定、权威和秩序，同时也象征掌控和组织能力。在财务上，皇帝预示着安定的阶段，可能意味着你未来的财务状态将变得稳定和有序。这张牌鼓励你采取具有长远眼光和纪律性的方法来管理你的资产和财务规划。
综合来看，你的财务情况似乎是从不稳定的状态（宝剑六）逐步走向清晰和新的机会（宝剑一），最终可能实现稳定和有序的发展（皇帝）。因此，建议你在目前和未来抓住机会，保持果断和清晰的头脑，并且制定一个明确的财务计划，以便达到稳定和有序的财务状态。
请记住塔罗牌阅读并不是预测未来的确定性工具，而是提供洞见和指导。对于任何财务决定，务必结合实际情况进行谨慎的考量，如果需要，也可以咨询专业的财务顾问。`
  }
]

export const Tarot: FC = observer(() => {
  const [tarotimages] = useState([
    { name: '宝剑八', tag: 'bj8' },
    { name: '宝剑二', tag: 'bj2' },
    { name: '宝剑皇后', tag: 'bjhh' },
    { name: '宝剑九', tag: 'bj9' },
    { name: '宝剑六', tag: 'bj6' },
    { name: '宝剑七', tag: 'bj7' },
    { name: '宝剑骑士', tag: 'bjqs' },
    { name: '宝剑三', tag: 'bj3' },
    { name: '宝剑十', tag: 'bj10' },
    { name: '宝剑侍童', tag: 'bjst' },
    { name: '宝剑四', tag: 'bj4' },
    { name: '宝剑王', tag: 'bjw' },
    { name: '宝剑五', tag: 'bj5' },
    { name: '宝剑一', tag: 'bj1' },
    { name: '吊人', tag: 'dr' },
    { name: '恶魔', tag: 'em' },
    { name: '皇帝', tag: 'hd' },
    { name: '皇后', tag: 'hh' },
    { name: '教皇', tag: 'jh' },
    { name: '节制', tag: 'jz' },
    { name: '金币八', tag: 'jb8' },
    { name: '金币二', tag: 'jb2' },
    { name: '金币皇后', tag: 'jbhh' },
    { name: '金币九', tag: 'jb9' },
    { name: '金币六', tag: 'jb6' },
    { name: '金币七', tag: 'jb7' },
    { name: '金币骑士', tag: 'jbqs' },
    { name: '金币三', tag: 'jb3' },
    { name: '金币十', tag: 'jb10' },
    { name: '金币侍童', tag: 'jbst' },
    { name: '金币四', tag: 'jb4' },
    { name: '金币王', tag: 'jbw' },
    { name: '金币五', tag: 'jb5' },
    { name: '金币一', tag: 'jb1' },
    { name: '力量', tag: 'll' },
    { name: '恋人', tag: 'lr' },
    { name: '命运之轮', tag: 'myzl' },
    { name: '魔术师', tag: 'mss' },
    { name: '女祭司', tag: 'njs' },
    { name: '权杖八', tag: 'qz8' },
    { name: '权杖二', tag: 'qz2' },
    { name: '权杖皇后', tag: 'qzhh' },
    { name: '权杖九', tag: 'qz9' },
    { name: '权杖六', tag: 'qz6' },
    { name: '权杖七', tag: 'qz7' },
    { name: '权杖骑士', tag: 'qzqs' },
    { name: '权杖三', tag: 'qz3' },
    { name: '权杖十', tag: 'qz10' },
    { name: '权杖侍童', tag: 'qzst' },
    { name: '权杖四', tag: 'qz4' },
    { name: '权杖王', tag: 'qzw' },
    { name: '权杖五', tag: 'qz5' },
    { name: '权杖一', tag: 'qz1' },
    { name: '审判', tag: 'sp' },
    { name: '圣杯八', tag: 'sb8' },
    { name: '圣杯二', tag: 'sb2' },
    { name: '圣杯皇后', tag: 'sbhh' },
    { name: '圣杯九', tag: 'sb9' },
    { name: '圣杯六', tag: 'sb6' },
    { name: '圣杯七', tag: 'sb7' },
    { name: '圣杯骑士', tag: 'sbqs' },
    { name: '圣杯三', tag: 'sb3' },
    { name: '圣杯十', tag: 'sb10' },
    { name: '圣杯侍童', tag: 'sbst' },
    { name: '圣杯四', tag: 'sb4' },
    { name: '圣杯王', tag: 'sbw' },
    { name: '圣杯五', tag: 'sb5' },
    { name: '圣杯一', tag: 'sb1' },
    { name: '世界', tag: 'sj' },
    { name: '死神', tag: 'ss' },
    { name: '塔', tag: 't' },
    { name: '太阳', tag: 'ty' },
    { name: '星星', tag: 'xx' },
    { name: '隐者', tag: 'yz' },
    { name: '愚人', tag: 'yr' },
    { name: '月亮', tag: 'yl' },
    { name: '战车', tag: 'zc' },
    { name: '正义', tag: 'zy' }
  ]);
  const [randomImages, SetRandomImages] = useState<{ name: string; tag: string; down?: string }[]>([]);
  const [tool, SetTool] = useState<ToolSchema>()
  const bindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const textareabindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const [historyCache, setHistoryCache] = useState<TarotSchema[]>([]);
  const [problem, SetProblem] = useState('');
  const [choosesModel, setChoosesModel] = useState(false);
  const [modelkey, setModelsKey] = useState(_Models[1])
  const [caseStop, SetcaseStop] = useState(true);
  const message = useMessage();
  const { userInfo } = userStore;
  const { getToolByLink, getToolModel } = toolStore;
  const [buttonpd, setButtonpd] = useState(true);
  const [isnullcontent, SetIsNullContent] = useState(true);
  const [riskInfo, SetRiskInfo] = useState<any>();

  const getRandomImages = async () => {
    const shuffledArray = [...tarotimages]; // 创建一个副本以避免修改原始数组
    const randomImage = [];
    while (randomImage.length < 3 && shuffledArray.length > 0) {
      const randomIndex = Math.floor(Math.random() * shuffledArray.length);
      const random = shuffledArray.splice(randomIndex, 1)[0];
      const isReversed = Math.random() < 0.5;
      let down = ''
      if (isReversed) { down = '正向' } else { down = '倒置' }
      randomImage.push({ ...random, down });
    }
    SetRandomImages(randomImage);
    return randomImage;
  };
  const [state, setState] = useState({
    value: InitValues,
    currentIndex: 0
  });
  const caseButtonClick = () => {
    setIsContent(!isContent)
    setButtonpd(false);
    const currentValue = state.value[state.currentIndex]
    SetProblem(currentValue.Problem);
    SetRandomImages([]);
    const { lastrandom, nowrandom, futurerandom } = currentValue.RandomImage;
    SetRandomImages((prevImages) => [...prevImages, lastrandom, nowrandom, futurerandom]);
    if (bindGenPrompt.current !== null) {
      bindGenPrompt.current.value = currentValue.Content;
    }
    const nextIndex = (state.currentIndex + 1) % state.value.length;
    setState({ ...state, currentIndex: nextIndex });
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'tarotDivination' });
      SetTool(data[0]);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getTarot();
      setHistoryCache(data);
    };

    fetchData();
  }, []);

  const handleTextChange = (value: string) => {
    SetProblem(value);
  };
  const handleChange = () => {
    if (!bindGenPrompt.current || loading) return;
  };
  const setViewhistory = (item: any) => {
    setIsContent(!isContent)
    SetProblem(item.problem);
    SetRandomImages([]);
    const { lastrandom, nowrandom, futurerandom } = item.parameters;
    SetRandomImages((prevImages) => [...prevImages, lastrandom, nowrandom, futurerandom]);
    if (bindGenPrompt.current !== null) {
      bindGenPrompt.current.value = item.content;
      setButtonpd(bindGenPrompt.current.value.length < 1);
    }
    SetcaseStop(true);
    if (item.contentCheck) {
      SetRiskInfo(item.contentCheck);
    }
  };
  useEffect(() => {
    if (problem.length < 1) {
      SetIsNullContent(true)
    } else {
      SetIsNullContent(false)
    }
  }, [problem])
  // 中断请求
  const controller = useRef(new AbortController());
  const [isContent, setIsContent] = useState(false);

  const scrollToBottom = useCallback((behavior: 'smooth' | 'auto' = 'smooth') => {
    if (!bindGenPrompt.current) return;
    bindGenPrompt.current.scrollTo({
      top: bindGenPrompt.current.scrollHeight,
      behavior
    });
  }, []);

  const generatingMessage = useCallback(
    throttle(() => {
      if (!bindGenPrompt.current) return;
      const isBottom =
        bindGenPrompt.current.scrollTop + bindGenPrompt.current.clientHeight + 150 >=
        bindGenPrompt.current.scrollHeight;

      isBottom && scrollToBottom('auto');
    }, 100),
    []
  );

  const [loading, setLoading] = useState(false);
  const delay = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, ms);
    });
  const handleGPTGenInfo = async () => {
    setButtonpd(false);
    setIsContent(true);
    if (problem.length <= 1) {
      message.warning({ title: '请先输入您想要咨询的问题！' })
      return;
    }
    if (bindGenPrompt.current !== null) {
      bindGenPrompt.current.value = '';
    }
    if (userInfo && userInfo.balance <= 0) {
      message.warning({ title: '由于余额不足, <任务模块>已经停止，请充值后再试。' })
      // onOpenPayModal()
      setLoading(false);
      return;
    }
    const randomImages = await getRandomImages();
    const aiwritemodel = modelkey.key;
    setLoading(true);
    const template = `我的问题是：${problem}。 过去的牌是：${randomImages[0].name}-${randomImages[0].down}，现在的牌是：${randomImages[1].name}-${randomImages[1].down}，未来的牌是：${randomImages[2].name}-${randomImages[2].down}`;
    const abortSignal = new AbortController();
    controller.current = abortSignal;
    let parameter = {
      lastrandom: randomImages[0],
      nowrandom: randomImages[1],
      futurerandom: randomImages[2]
    };
    // onOpenView();
    // 流请求，获取数据
    try {
      const ret = await streamFetch({
        data: {
          messages: [
            {
              content:
                '你现在是一个塔罗牌大师，把我给你的每张牌解释一下，然后结合我给你的提问帮我算一下',
              role: 'system'
            },
            {
              content: template,
              role: 'user'
            }
          ] as any[],
          chatId: '',
          stream: true,
          appId: await getToolModel('TarotDivination'),
          model: aiwritemodel,
          toolParams: {
            problem: problem,
            parameters: parameter,
            model: modelkey.key,
            template: template,
            toolsname: tool ? tool.name : '',
            toolsid: tool ? tool._id : ''
          }
        },
        onMessage: (text: string) => {
          if (!bindGenPrompt.current) return;
          bindGenPrompt.current.value = bindGenPrompt.current.value + text;
          setIsContent(false);
          generatingMessage();
          if (!textareabindGenPrompt.current) return;
          textareabindGenPrompt.current.value = bindGenPrompt.current.value;
        },
        abortSignal
      });
      // 等待输出内容结束
      abortSignal.signal.aborted && (await delay(500));
      // const res = await postCreateTarot({
      //   problem: problem,
      //   parameters: parameter,
      //   content: bindGenPrompt.current ? bindGenPrompt.current.value : '',
      //   model: modelkey.key,
      //   template: template,
      //   toolid: tool ? tool._id : ''
      // });
      const res = await gettoolhis({ _id: ret.newChatId })
      if (res) {
        SetRiskInfo(res.contentCheck)
      }
    } catch (err: any) {
      if (!(err == 'The user aborted a request.'
        || err == 'BodyStreamBuffer was aborted'
        || err == 'Fetch is aborted')) {
        message.warning({ title: typeof err === 'string' ? err : err?.message || '聊天出错了~' })
      } else {
        let parameter = {
          lastrandom: randomImages[0],
          nowrandom: randomImages[1],
          futurerandom: randomImages[2]
        };
        await postCreateTarot({
          problem: problem,
          parameters: parameter,
          content: bindGenPrompt.current ? bindGenPrompt.current.value : '',
          model: modelkey.key,
          template: template,
          toolid: tool ? tool._id : ''
        });
      }
      if (err.message && err.message.includes('不足')) {
        // onOpenPayModal()
      }
      setLoading(false);
    }
    handleChange();
    const data = await getTarot();
    setHistoryCache(data);
    setLoading(false);
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const handleBlur = () => {
    setIsFocused(false)
    if (problem.trim() === '') {
      setErrorMessage('请输入内容');
    } else {
      setErrorMessage('');
    }
  };

  return (<Flex key={'tarotDivination'} className={styles.toollistBox}>
    <Flex className={styles.listBox}>
      <Box className={styles.bgbox}></Box>
      <Box zIndex={2}>
        <ComTitle
          key={tool?.name.toString()}
          color={true}
          setViewhistory={setViewhistory}
          icon={tool ? tool.icon.toString() : ''}
          title={tool ? tool.name.toString() : ''}
          historyCache={historyCache}
          bindprompt={bindGenPrompt}
          historytitle='problem'
          historycontent='content'
          onDelete={async (id: string) => {
            await deleteTarot({ _id: id });
            const data = await getTarot();
            setHistoryCache(data);
            if (bindGenPrompt.current !== null) {
              bindGenPrompt.current.value = '';
            }
          }} />
      </Box>
      <Box className={styles.contentsBox}>
        <ScrollBarBox>
          <Box>
            <Flex className={styles.allbox}>
              <Box className={styles.titlebox}>
                <Text className={styles.title} >
                  请输入您的问题并在心中默念3秒<span className={styles.titletips}>*</span>
                </Text>
              </Box>
              <Box className={`${styles.textareabox} ${isFocused ? styles.focused : errorMessage ? styles.error : ''}`}>
                <Textarea
                  className={styles.textarea}
                  rows={6}
                  placeholder={'例如：近期爱情运势'}
                  value={problem}
                  onChange={(e) => SetProblem(e.target.value)}
                  onBlur={handleBlur}
                  onFocus={() => setIsFocused(true)}
                  maxLength={300}
                />
                <Text className={styles.maxText}>
                  {problem ? problem.length : 0}/300
                </Text>
                {!isFocused && errorMessage && (
                  <Flex className={styles.tipstext}>
                    <Icon as={require('@/assets/svg/errorIcon.svg').ReactComponent} />
                    <p>{errorMessage}</p>
                  </Flex>
                )}
              </Box>
            </Flex>
          </Box>
          <Box>
            {randomImages.length > 0 && (
              <Flex className={styles.allbox}>
                <Box className={styles.titlebox}>
                  <Text className={styles.title} >
                    您抽到了
                  </Text>
                </Box>
                <Box className={styles.imgboxs}>
                  {randomImages.map((item) => (
                    <Flex flexDir={'column'} mr={'18px'} key={item.tag}>
                      <Flex key={item.name} className={styles.imgbox}>
                        {item.down && item.down === '倒置' ? (
                          <Image className={styles.image} src={require(`@/assets/imgs/tarotImages/${item.tag}.jpg`)}
                            style={{ transform: 'rotate(180deg)' }} />
                        ) : (
                          <Image className={styles.image} src={require(`@/assets/imgs/tarotImages/${item.tag}.jpg`)}
                          />
                        )}
                      </Flex>
                      <Text className={styles.imgtext}>
                        {item.name}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              </Flex>
            )}
          </Box>
        </ScrollBarBox>
      </Box>
      <Flex className={styles.footbutton}>
        <ComButton
          isnull={isnullcontent}
          loading={loading}
          color={true}
          setLoading={setLoading}
          casebut={'示例'}
          startbut={'生成'}
          stopbut={'停止'}
          stopClick={() => { controller.current?.abort() }}
          startClick={handleGPTGenInfo}
          caseStop={true}
          caseButtonClick={caseButtonClick}
          // isnull={isDisabled}
          chooseModel={choosesModel}
          setChooseModel={setChoosesModel}
          modelkey={modelkey}
          setModelsKey={setModelsKey}
          _Models={_Models}
        ></ComButton>
      </Flex>
    </Flex>
    <Flex className={styles.previewBox}>
      <Contentpreview
        title={'塔罗牌解释'}
        titlePrompt={'*该结果仅供参考，请勿盲目迷信'}
        bindprompt={bindGenPrompt}
        loading={loading}
        buttonpd={buttonpd}
        isContent={isContent}
        riskInfo={riskInfo}
      />
    </Flex>
  </Flex>)
})