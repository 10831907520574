import { FC, useCallback, useState } from "react";
import styles from './index.module.scss'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { useLocation, useNavigate } from "react-router-dom";
import { WiseImage } from '@/components/Image'
import { globalStore } from "@/store/global";
import { NavAnimation } from "./navAnimation";
import { MonthlyCard } from "../MonthlyCard";
import { SHOW_PRICE_SCALE } from "@/utils/common";
import { uiStrore } from "@/store/ui";
import { observer } from "mobx-react-lite";
import { VIPHYXY } from "@/pages/login/LoginType";
import { NewVersion } from "./NewVersion";
import { ipcRendererInvoke } from "@/utils/electron";
import { userStore } from "@/store/user";
import { imStore } from "@/store/im";
import { useTranslation } from "react-i18next";

/**
 * icon遵循命名规则
 * 当前路由页面的icon需要加_active
 */


interface NavProps {
  userInfo?: UserResult
}
export const Nav: FC<NavProps> = observer((props) => {
  const { userInfo } = props;
  const { t } = useTranslation();

  const navs = [
    { icon: 'nav_ai', text: 'AI', route: '/chat' },
    { icon: 'creation', text: t('Creations'), route: '/creation' },
    { icon: 'tools', text: t('Tool'), route: '/tools' },
    { icon: 'agents', text: t('Agents'), route: '/agents' },
    { icon: 'im', text: t('IM'), route: '/im' },
  ]

  const location = useLocation();
  const navigate = useNavigate();
  const [showNewVersion, setShowNewVersion] = useState(false);
  const [hyxyOpen, setHyxyOpen] = useState(false); // 用户协议
  const { updateUserinfo } = userStore
  const { openVip, setOpenVip, updateDownloaded, releaseNotes, updateIsWindow } = uiStrore;
  const { unread } = imStore;
  const navIcon = useCallback((route: string) => {
    switch (route) {
      case '/chat':
        return <NavAnimation.Chat />;
      case '/creation':
        return <NavAnimation.Creation />;
      case '/tools':
        return <NavAnimation.Tool />;
      case '/agents':
        return <NavAnimation.Agent />;
      case '/im':
        return <NavAnimation.IM />;
      default:
        return <Icon className={styles.svg} as={require(`@/assets/svg/${route}.svg`).ReactComponent} />
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const updateVersion = () => {
    if (updateIsWindow) {
      ipcRendererInvoke('openUrl', 'https://www.wisetalker.cn/')
    } else {
      ipcRendererInvoke('updateVersion')
    }
  }

  return <Box className={styles.nav}>
    <Box className={styles.logo}>
      <WiseImage src={require('@/assets/logo.png')} />
    </Box>
    <Box className={styles.navs}>
      {
        navs.map(item => {
          const isActive = location.pathname === item.route;
          return <Box className={`${styles.navItem} ${isActive ? styles.active : ''}`} key={item.route}
            onClick={() => navigate(item.route)}>
            {
              isActive ? navIcon(item.route)
                : <Box className={styles.svgContent}>
                  <Icon className={styles.svg} as={require(`@/assets/svg/${item.icon}${isActive ? '_active' : ''}.svg`).ReactComponent} />
                </Box>
            }
            {
              item.route === '/im' && unread > 0 && <Box className={styles.unread}>{unread}</Box>
            }
            <Box className={styles.text} style={{ visibility: isActive ? 'hidden' : 'visible' }}>{item.text}</Box>
          </Box>
        })
      }
    </Box>
    <Box className={styles.bottomNavs}>
      {
        updateDownloaded && <Box className={styles.newVersion} onClick={() => setShowNewVersion(true)}>
          <Icon className={styles.icon} as={require(`@/assets/svg/new_version.svg`).ReactComponent} />
          <Text className={styles.text}>{t('NewVersion')}</Text>
        </Box>
      }
      {/* <Box className={styles.feedback}>
        <Icon className={styles.svg} as={require(`@/assets/svg/feedback.svg`).ReactComponent} />
        <Box className={styles.text}>反馈</Box>
      </Box> */}
      <Flex className={styles.vipbox} onClick={(e) => {
        e.stopPropagation();
        setOpenVip(true);
      }}>
        <Icon as={userInfo?.monVip ? require(`@/assets/svg/navVIP.svg`).ReactComponent
          : require(`@/assets/svg/navNoVIP.svg`).ReactComponent} />
        <Flex className={userInfo?.monVip ? styles.vipuserbox : styles.notvipuserbox}>
          <Icon as={userInfo?.monVip ? require(`@/assets/svg/navisVip.svg`).ReactComponent
            : require(`@/assets/svg/navnotVip.svg`).ReactComponent} />
          <Text>{userInfo?.monVip ? t('Availabled') : t('NotAvailable')}</Text>
        </Flex>
        <Box className={styles.vipBoxModal}>
          <Box className={userInfo?.monVip ? styles.vipmodel : styles.notvipmodel}>
            <Flex className={styles.contentbox}>
              <Text className={styles.title}>{t('WSVip')}</Text>
              <Text className={styles.date}>
                {userInfo?.monVip ?
                  `${new Date(userInfo?.limit.vipPayList[userInfo?.limit.vipPayList.length - 1]?.endTime).toLocaleDateString()}${t('Expiration')}`
                  : t('BenefitsNotActivated')
                }
              </Text>
              <Flex>
                <Text className={styles.points}>{t('Balance')}：<span>{userInfo?.balance ? (userInfo.balance * SHOW_PRICE_SCALE).toFixed(2) : '0.00'}</span></Text>
                <Icon className={styles.pointssvg} as={userInfo?.monVip ?
                  require('@/assets/svg/vipbalance.svg').ReactComponent
                  : require('@/assets/svg/notvipbalance.svg').ReactComponent} />
              </Flex>
            </Flex>
            <Flex className={styles.goOpenbox}
              onClick={(e) => {
                e.stopPropagation();
                setOpenVip(true);
              }}>
              <Text>{userInfo?.monVip ? t('Renew') : t('activation')}</Text>
            </Flex>
          </Box>
        </Box>
      </Flex>
      {
        userInfo ? <Box className={styles.userInfo} onClick={() => { updateUserinfo(); navigate('/personal') }}>
          <WiseImage className={styles.userAvatar} src={userInfo.avatar} />
        </Box>
          : <Box className={styles.login} onClick={() => globalStore.setLoginIsOpen(true)}>
            <Icon className={styles.svg} as={require(`@/assets/svg/login.svg`).ReactComponent} />
            <Box className={styles.text}>{t('Login')}</Box>
          </Box>
      }
    </Box>
    <MonthlyCard isOpen={openVip} onClose={() => setOpenVip(false)} onOpen={() => { setHyxyOpen(true); setOpenVip(false) }} />
    <VIPHYXY isOpen={hyxyOpen} onClose={() => { setHyxyOpen(false); setOpenVip(true) }} />
    <NewVersion open={showNewVersion} releaseNotes={releaseNotes} onClose={() => setShowNewVersion(false)}
      onUpdate={updateVersion} isWindow={updateIsWindow} />
  </Box>
})