import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Welcome } from "../Welcome";
import { observer } from "mobx-react-lite";
import { chatStore } from "@/store/chat";
import { AIContentInfo } from "./AIContent";
import { userStore } from "@/store/user";
import { _AI_DefaultModel } from "@/utils/common";
import { WiseImage } from "@/components/Image";
import { debounce } from "lodash";
import Loading from "@/components/Loading";

import styles from './index.module.scss'

const agentImgToSvg: Record<string, string> = {
  'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7401.png': 'agent_default_icon',
  'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7402.png': 'agent_primary_icon',
  'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7403.png': 'agent_red_icon',
  'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7404.png': 'agent_green_icon',
  'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7405.png': 'agent_orange_icon',
}

const ChatContent: FC = observer(() => {
  const { currentType } = chatStore;
  return <>
    {
      currentType === 'AI' ? <AIContent /> : <PluginContent />
    }
  </>
})

const AIContent: FC = observer(() => {
  const { modelInfo, currentChat, needReRender, generateMsg, aiChatInfo, playingId } = chatStore;
  const { userInfo } = userStore;
  const containerRef = useRef<HTMLDivElement>(null);
  const [length, setLength] = useState(10);
  const list = useRef<ChatInfoHistory[]>([])
  const info = useRef<AIChatInfoResult | undefined>();
  const maxLen = useRef(0);
  const [isInited, setIsInited] = useState(false);
  const [selfRender, setSelfRender] = useState(false);
  info.current = aiChatInfo.get(currentChat?.chatId || 'newChatId');
  const newsetGenerateMsg = generateMsg.get(currentChat?.chatId || 'newChatId');

  useEffect(() => {
    const inputDom = document.getElementById('inputArea') as HTMLInputElement;
    if (!inputDom || !containerRef.current) return;
    // 监听输入框的高度变化，用于调整滚动条
    const observer = new ResizeObserver(() => {
      if (!inputDom || !containerRef.current) return;
      const height = inputDom.offsetHeight;
      containerRef.current!.style.height = `calc(100% - ${height - 100}px)`; // 100% - 输入框高度
      containerRef.current?.scrollTo({ top: 0, behavior: 'auto' });
    });
    observer.observe(inputDom);
  }, [])

  useEffect(() => {
    containerRef.current?.addEventListener('scroll', () => {
      const toBottom = document.getElementById('toBottom');
      if (containerRef.current!.scrollTop < -20) {
        toBottom && (toBottom.style.display = 'flex');
      } else {
        toBottom && (toBottom.style.display = 'none');
      }
    });
  }, [])

  useEffect(() => {

    // 滚动到底部，防止加载全部
    containerRef.current?.scrollTo({ top: 0, behavior: 'auto' });
    setIsInited(false);
    // eslint-disable-next-line
  }, [currentChat?.chatId])

  useEffect(() => {
    maxLen.current = info.current?.history?.length || 0;
    if (!isInited) {
      // 滚动到底部，防止加载全部
      containerRef.current?.scrollTo({ top: 0, behavior: 'auto' });
      setIsInited(true);
      setLength(length === 10 ? 12 : 10); // 用于触发useEffect
      return;
    }
    const _LIST = info.current?.history?.slice(-(length > maxLen.current ? maxLen.current : length))?.reverse() || [];
    list.current = _LIST;
    //外部手动触发重新渲染
    setSelfRender(!selfRender);
    // eslint-disable-next-line 
  }, [length, needReRender])

  useEffect(() => {
    const observerElement = document.querySelector('#loadMore') as HTMLDivElement;
    const showLoadMore = document.getElementById('showLoadMore');

    if (!observerElement) {
      return;
    }
    // 监听可视dom 用于渲染更多
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (length >= maxLen.current) {
            observer.unobserve(entry.target);
            showLoadMore && (showLoadMore.style.display = 'none');
          } else {
            observerElement.style.display = 'none';
            showLoadMore && (showLoadMore.style.display = 'flex');
            loadMore();
            setTimeout(() => {
              observerElement.style.display = 'block';
              showLoadMore && (showLoadMore.style.display = 'none');
            }, 300)
          }
        }
      });
    });

    observer.observe(observerElement as Element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [list, length])

  const toListBottom = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const loadMore = debounce(() => {
    isInited && setLength(length + 12);
  }, 100)


  const renderContent = useMemo(() => {
    // 由于列表已经反转，所以这里需要判断第一个是否是AI
    const lastIsAI = list.current[0]?.obj === 'AI';
    let reGenerate = '';
    list.current.some(item => {
      if (item.obj === 'Human') {
        reGenerate = item.value;// 用于重新生成新问题
      }
      return item.obj === 'Human';
    })

    return <>
      {
        list && list.current.map((item, index) => {
          return <AIContentInfo
            key={item._id}
            avatar={{ userAvatar: userInfo?.avatar || '', aiAvatar: modelInfo[item.modelName]?.avatar || '' }}
            chatItem={item}
            modelNames={modelInfo[item.modelName]?.name || ''}
            price={modelInfo[item.modelName]?.price || 0}
            reGenerate={lastIsAI && index === 0 ? reGenerate : ''}
            value={item.value}
            isVip={item.isVip}
            riskInfo={item?.contentCheck}
          />
        })
      }
    </>
    // eslint-disable-next-line
  }, [selfRender, playingId])

  const isAgent = currentChat?.modelId ? currentChat?.modelId !== _AI_DefaultModel && !currentChat.isPlugin : false;
  const showWelcome = currentChat?.isPlugin ? true : !info.current?.history.length && !currentChat?.chatId && !isAgent;

  return <Box flex={1} className={styles.chatContainer}>
    {
      showWelcome ? <Welcome.AI />
        : <Box className={`${styles.listBox}`} ref={containerRef}>
          <Box flexGrow={1} flexShrink={1}></Box>
          <Box className={styles.toBottom} id="toBottom" onClick={toListBottom}>
            <Icon as={require('@/assets/svg/to_bottom.svg').ReactComponent} />
          </Box>
          <Box className={styles.bottomPos}></Box>
          {
            newsetGenerateMsg?.status === 'loading' && <AIContentInfo
              avatar={{ userAvatar: userInfo?.avatar || '', aiAvatar: modelInfo[newsetGenerateMsg.modelName].avatar }}
              chatItem={newsetGenerateMsg} modelNames={modelInfo[newsetGenerateMsg.modelName].name} value={newsetGenerateMsg.value}
            />
          }
          {renderContent}
          {
            isAgent && <Box className={styles.agent}>
              {
                info.current?.model?.avatar && agentImgToSvg[info.current?.model?.avatar]
                  ? <Icon className={styles.avatar} as={require(`@/assets/svg/${agentImgToSvg[info.current?.model?.avatar]}.svg`).ReactComponent} />
                  : <WiseImage src={info.current?.model?.avatar} className={styles.avatar} />
              }
              <Text className={styles.title}>{info.current?.model?.name}</Text>
              <Text className={styles.desc}>{info.current?.model?.intro || '该智能体暂无简介'}</Text>
            </Box>
          }
          <Box w={0} h={0} id='loadMore' className={styles.loadMore} />
          <Box id='showLoadMore' className={styles.showLoadMore}>
            <Loading.Dot />
          </Box>
          <Box className={styles.headerPos}></Box>
        </Box>
    }
  </Box >
})

const PluginContent: FC = observer(() => {
  const { modelInfo, currentChat, needReRender, generatePluginMsg, pluginChatInfo, playingId } = chatStore;
  const { userInfo } = userStore;
  const containerRef = useRef<HTMLDivElement>(null);
  const [length, setLength] = useState(10);
  const list = useRef<string[]>()
  const info = useRef<PluginChatInfo | undefined>();
  const maxLen = useRef(0);
  const [isInited, setIsInited] = useState(false);
  const [selfRender, setSelfRender] = useState(false);
  info.current = pluginChatInfo.get(currentChat?.chatId || 'newChatId');
  const newsetGenerateMsg = generatePluginMsg.get(currentChat?.chatId || 'newChatId');

  useEffect(() => {
    const inputDom = document.getElementById('inputArea') as HTMLInputElement;
    if (!inputDom || !containerRef.current) return;
    // 监听输入框的高度变化，用于调整滚动条
    const observer = new ResizeObserver(() => {
      if (!inputDom || !containerRef.current) return;
      const height = inputDom.offsetHeight;
      containerRef.current!.style.height = `calc(100% - ${height - 100}px)`; // 100% - 输入框高度
      containerRef.current?.scrollTo({ top: 0, behavior: 'auto' });
    });
    observer.observe(inputDom);
  }, [])

  useEffect(() => {
    containerRef.current?.addEventListener('scroll', () => {
      const toBottom = document.getElementById('toBottom');
      if (containerRef.current!.scrollTop < -20) {
        toBottom && (toBottom.style.display = 'flex');
      } else {
        toBottom && (toBottom.style.display = 'none');
      }
    });
  }, [])

  useEffect(() => {
    // 滚动到底部，防止加载全部
    containerRef.current?.scrollTo({ top: 0, behavior: 'auto' });
    setIsInited(false);
    // eslint-disable-next-line
  }, [currentChat?.chatId])

  useEffect(() => {
    maxLen.current = Object.keys(info.current?.mapping || {}).length;
    if (!isInited) {
      // 滚动到底部，防止加载全部
      containerRef.current?.scrollTo({ top: 0, behavior: 'auto' });
      setIsInited(true);
      setLength(length === 10 ? 12 : 10); // 用于触发useEffect
      return;
    }
    const _LIST = Object.keys(info.current?.mapping || {})?.slice(-(length > maxLen.current ? maxLen.current : length))?.reverse() || [];
    list.current = _LIST;
    //外部手动触发重新渲染
    setSelfRender(!selfRender);
    // eslint-disable-next-line 
  }, [length, needReRender])

  useEffect(() => {
    const observerElement = document.querySelector('#loadMore') as HTMLDivElement;
    const showLoadMore = document.getElementById('showLoadMore');

    if (!observerElement) {
      return;
    }
    // 监听可视dom 用于渲染更多
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (length >= maxLen.current) {
            observer.unobserve(entry.target);
            showLoadMore && (showLoadMore.style.display = 'none');
          } else {
            observerElement.style.display = 'none';
            showLoadMore && (showLoadMore.style.display = 'flex');
            loadMore();
            setTimeout(() => {
              observerElement.style.display = 'block';
              showLoadMore && (showLoadMore.style.display = 'none');
            }, 300)
          }
        }
      });
    });

    observer.observe(observerElement as Element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [list, length])

  const loadMore = debounce(() => {
    isInited && setLength(length + 12);
  }, 100)

  const toListBottom = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const renderContent = useMemo(() => {
    return <>
      {
        list.current?.map((item) => {
          const msg = info.current?.mapping[item].message;

          return (msg?.author.role === 'assistant' || msg?.author.role === 'user') && <AIContentInfo
            key={msg.id}
            avatar={{ userAvatar: userInfo?.avatar || '', aiAvatar: modelInfo['gpt-4o']?.avatar || '' }}
            chatItem={{
              createTime: new Date(msg.create_time * 1000).toString(),
              modelName: 'gpt-4o',
              obj: msg.author.role === 'assistant' ? 'AI' : 'Human',
              value: msg.content.parts.join(''),
              _id: msg.id,
              status: 'success'
            }}
            modelNames={modelInfo['gpt-4o']?.name || ''}
            price={modelInfo['gpt-4o']?.price || 0}
            value={msg.content.parts}
            isPlugin
            riskInfo={info.current?.mapping[item]?.contentCheck}
          />
        })
      }
    </>
    // eslint-disable-next-line
  }, [selfRender, playingId])

  const isAgent = currentChat?.modelId ? currentChat?.modelId !== _AI_DefaultModel && !currentChat.isPlugin : false;
  const showWelcome = !currentChat?.isPlugin ? true : !Object.keys(info.current?.mapping || {}).length && !currentChat?.chatId && !isAgent

  return <Box flex={1} className={styles.chatContainer}>
    {
      showWelcome ? <Welcome.Plugin />
        : <Box className={`${styles.listBox}`} ref={containerRef}>
          <Box flexGrow={1} flexShrink={1}></Box>
          <Box className={styles.toBottom} id="toBottom" onClick={toListBottom}>
            <Icon as={require('@/assets/svg/to_bottom.svg').ReactComponent} />
          </Box>
          <Box className={styles.bottomPos}></Box>
          {
            newsetGenerateMsg?.message.status === 'generating' && <AIContentInfo
              avatar={{ userAvatar: userInfo?.avatar || '', aiAvatar: modelInfo['gpt-4o'].avatar }}
              chatItem={{
                createTime: new Date(newsetGenerateMsg.message.create_time * 1000).toString(),
                modelName: 'gpt-4o',
                obj: newsetGenerateMsg.message.author.role === 'assistant' ? 'AI' : 'Human',
                value: newsetGenerateMsg.message.content.parts.join(''),
                _id: newsetGenerateMsg.message.id,
                status: 'loading'
              }} modelNames={modelInfo['gpt-4o'].name} value={newsetGenerateMsg.message.content.parts}
              isPlugin
            />
          }
          {renderContent}
          <Box w={0} h={0} id='loadMore' className={styles.loadMore} />
          <Box id='showLoadMore' className={styles.showLoadMore}>
            <Loading.Dot />
          </Box>
          <Box className={styles.headerPos}></Box>
        </Box>
    }
  </Box >
})
export default ChatContent;