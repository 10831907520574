import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, useEffect, useRef } from "react";
import { WiseImage } from "@/components/Image";
import Markdown from "@/components/Markdown";
import dayjs from "dayjs";
import { copyDataFunc } from "@/utils/common";
import { chatStore } from "@/store/chat";
import { voiceBroadcast } from "@/utils/chat";
import Image from "@/components/Markdown/img/Image";
import { useMessage } from "@/hooks/useMessage";
import { userStore } from "@/store/user";
import { uiStrore } from "@/store/ui";


import styles from './index.module.scss'
import i18n from "@/utils/i18n";
import { useTranslation } from "react-i18next";

interface AIContentInfoProps {
  avatar: {
    userAvatar: string
    aiAvatar: string
  }
  chatItem: ChatInfoHistory
  reGenerate?: string
  value: string | string[]
  isPlugin?: boolean
  modelNames?: string
  price?: number
  riskInfo?: ChatInfoHistory['contentCheck']
}
export const AIContentInfo: FC<AIContentInfoProps> = (props) => {
  const { chatItem, avatar, reGenerate, modelNames, isPlugin, value, price, riskInfo } = props;
  const itemRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const isAI = chatItem.obj === 'AI';
  const isFunctionCall = Array.isArray(value);
  let valStr = ''
  if (isFunctionCall) {
    value.forEach(str => {
      const noText = str.startsWith('\n\n```function_call') || str.endsWith('```\n\n') || str.startsWith('\n\n```finish_reason')
      if (!noText) {
        valStr = str;
      }
    })
  }
  const val = (isPlugin || isFunctionCall) ? valStr : value;
  const riskValue = riskInfo?.status !== false ? val : (`${t("ContentMayContains")}:${riskInfo.labels?.split(',').map(str => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ` || t('ContentRiskBlocked'))
  return <Box ref={itemRef} className={styles.chatItemBox}>
    <BaseContent
      avatar={isAI ? avatar.aiAvatar : avatar.userAvatar}
      time={chatItem.createTime}
      model={modelNames || chatItem.modelName}
      isAI={isAI}
      id={chatItem._id}
      urls={chatItem.imgUrls}
      value={!isAI ? val : riskValue}
      isGenerate={chatItem.status === 'loading'}
      reGenerate={reGenerate}
      price={price}
    />
  </Box>
}

interface BaseContentProps {
  appName?: string
  avatar: string
  time: Date | string
  model: string
  isAI: boolean
  value: string
  price?: number
  urls?: string[]
  id: string
  isGenerate?: boolean
  reGenerate?: string
}
const BaseContent: FC<BaseContentProps> = (props) => {
  const { avatar, isAI, model, appName, time, value, urls, id, isGenerate, reGenerate, price } = props;
  const { copyData } = copyDataFunc();
  const { userInfo } = userStore;
  const { setQuote, setOtherStartQuestion, setPlayingId, playingId } = chatStore;
  const { setOpenVip } = uiStrore;
  const quoteRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const androidRef = useRef<{ ttsWS: WebSocket; audioPlayer: any; }>();
  const message = useMessage();
  const { t } = useTranslation();
  let humanImg: string[] = [];
  let humalVal = value;
  if (!isAI) {
    const matchImg = /!\[(.*?)\]\((.*?)\)/mg
    let matcher: RegExpExecArray | null
    if (urls?.length) {
      urls.forEach(url => {
        humanImg.push(url)
      })
    } else {
      while ((matcher = matchImg.exec(value)) !== null) {
        humanImg.push(matcher[2])
        humalVal = humalVal.replace(matcher[0] + '\n', '')
      }
    }
  }

  const quoteReg = /^\[.*\]\s---------------------------------------------\s/;

  let quoteStr = '';
  if (quoteReg.test(humalVal)) {
    const quoteAllString = quoteReg.exec(humalVal)?.[0] || '';
    humalVal = humalVal.replace(quoteAllString, '');

    const getStrReg = /^\[(.*?)\]/g;

    let matcher: RegExpExecArray | null;
    while ((matcher = getStrReg.exec(quoteAllString)) !== null) {
      quoteStr = matcher[1];
    }
  }

  // 用于计算引用的宽度
  useEffect(() => {
    if (contentRef.current && quoteRef.current) {
      quoteRef.current.style.width = contentRef.current.clientWidth + 'px';
      quoteRef.current.style.display = 'block';
    }
  }, [])

  const copy = () => {
    copyData(value);
    message.success({ title: '复制成功' });
  }

  const onQuote = () => {
    setQuote({
      text: isAI ? value : humalVal, //humanImg?.map(_ => '[图片]').join('') +  
      model: isAI ? model : '我',
      urls: humanImg || [],
      originText: value,
      id
    });
  }

  const play = () => {
    if (playingId === id) {
      if (window['speechSynthesis'] === undefined) {
        androidRef.current?.ttsWS.close()
        androidRef.current?.audioPlayer.reset()
      } else {
        window.speechSynthesis.cancel();
      }
      setPlayingId('');
    } else {
      if (window['speechSynthesis'] === undefined) {
        // androidPlayAudio(value) // 安卓播放音频 暂时不支持
      } else {
        voiceBroadcast({
          text: value,
          onEndCallback: () => {
            setPlayingId(''); // 朗读结束时更新状态
          }
        });
      }
      setPlayingId(id);
    }
  }

  return <Box flex={1}>
    <Box className={`${styles.chatItem}  ${isAI ? styles.ai : styles.user}`}>
      {
        <Box className={styles.avatar} order={isAI ? 0 : 1}>
          <WiseImage src={avatar} alt="" />
        </Box>
      }
      <Box className={styles.info}>
        {
          appName && isAI && <Text className={styles.app}>{appName}</Text>
        }
        <Box className={appName ? styles.appDesc : styles.desc}>
          {
            isAI && <Text className={styles.model}>{model}</Text>
          }
          <Text className={styles.time}>{dayjs(time).format('MM-DD HH:mm')}</Text>
        </Box>
        {
          !isAI && humanImg.length ? humanImg.map((item, index) => {
            return <Box key={index} className={styles.userImg}>
              <Image src={item} />
            </Box>
          }) : null
        }
        <Box className={styles.content} ref={contentRef}>
          <Box className={`${styles.text} ${isAI ? styles.ai : ''} ${isGenerate ? styles.ing : ''}`}>
            {!isAI ? (
              <div className="markdown" style={{ whiteSpace: 'pre-wrap' }}>
                {humalVal}
              </div>
            ) : <Markdown source={value} />
            }
            {
              isGenerate && <Box className={styles.loading}>
                <Text className={styles.ing}>{t('AIGenerating')}</Text>
                <Box className={styles.dot1}></Box>
                <Box className={styles.dot2}></Box>
                <Box className={styles.dot3}></Box>
              </Box>
            }
          </Box>
          {
            !isGenerate && <Box className={styles.bottomInfo}>
              <Box className={styles.operates}>
                <Box className={styles.icon} onClick={copy}>
                  <Icon as={require('@/assets/svg/copy.svg').ReactComponent} />
                </Box>
                <Box className={styles.icon} onClick={play}>
                  {
                    playingId === id ? <Box className={`${styles.playing} ${!isAI ? styles.humanPlaying : ''}`}>
                      <Text className={styles.line}></Text>
                      <Text className={styles.line}></Text>
                      <Text className={styles.line}></Text>
                      <Text className={styles.line}></Text>
                    </Box> :
                      <Icon as={require(`@/assets/svg/play.svg`).ReactComponent} />
                  }
                </Box>
                {
                  reGenerate && <Box className={`${styles.icon} ${styles.regen}`} onClick={() => setOtherStartQuestion(reGenerate)}>
                    <Icon as={require('@/assets/svg/refresh.svg').ReactComponent} />
                  </Box>
                }
              </Box>
              {
                isAI && (userInfo?.monVip ?
                  <Box className={styles.vipprice}>
                    <Icon as={require('@/assets/svg/chatisVip.svg').ReactComponent} />
                    <Text>{t('VipNoPoints')}</Text>
                  </Box>
                  :
                  <Box className={styles.price}>
                    <Text>{t('PointsConsumed')}：{price ? price / 2 : '50'}</Text>
                    <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    <Icon className={styles.vipsvg} as={require('@/assets/svg/navisVip.svg').ReactComponent} />
                    <Text className={styles.viptext} onClick={() => setOpenVip(true)}>{t('FreeMembership')}</Text>
                    <Icon className={styles.vipsvg} as={require('@/assets/svg/arrow_r.svg').ReactComponent} />
                  </Box>
                )}
            </Box>
          }

        </Box>
        {
          quoteStr && <Box className={styles.quote} ref={quoteRef} display='none'>
            <Text className={styles.quoteText}>{quoteStr}</Text>
          </Box>
        }
      </Box>
    </Box>
  </Box>
}