import { Box, Flex, Icon, Stack, Switch, Text, Textarea, Tooltip } from "@chakra-ui/react";
import { FC, useState } from "react";
import { CreationTitle, CreationTitleBox } from "../";

import styles from './index.module.scss'
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";
import { RobotIcon } from "@/pages/tools/WechatRobot";
import { useTranslation } from "react-i18next";

const _MAX_LENGTH = 500;
interface CreationPromptProps {
  isOn: boolean
  setIsOn: (value: boolean) => void
  systemPromptTip?: string
}
export const CreationSwitch: FC<CreationPromptProps> = (props) => {
  const { isOn, setIsOn, systemPromptTip } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };
  return <Box className={styles.prompt}>
    <Box className={styles.title}>
      <CreationTitleBox className={styles.text}>
        <CreationTitle title={t('creation.PureMusic')} />
        <Box w={'5px'}></Box>
        {systemPromptTip &&
          <Tooltip>
            <Icon onClick={() => setOpen(true)} />
          </Tooltip>
        }
      </CreationTitleBox>

    </Box >
    <Box className={`${styles.switch} ${isOn ? styles.switch_on : ''}`} onClick={toggleSwitch}>
      <Box className={styles.switch_handle}></Box>
    </Box>
    <CModal isOpen={open} onClose={() => setOpen(false)}>
      <Flex className={styles.notbalancebox}>
        <Flex className={styles.notbalanceTitleBox}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <RobotIcon />
            <Text>{t('creation.PureMusic')}</Text>
          </Flex>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
        </Flex>
        <Text className={styles.texttips}>{systemPromptTip}</Text>
        <Flex className={styles.modelbuttonbox}>
          <CButton className={styles.Savebutton} onClick={() => setOpen(false)}>
            {t('ISee')}
          </CButton>
        </Flex>
      </Flex>
    </CModal>
  </Box >
}