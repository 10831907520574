import { observer } from "mobx-react-lite";
import { Box, Flex, useDisclosure, Text, Icon, Textarea, Image } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from './index.module.scss'
import { ComTitle } from "../components/ComTitle";
import { ScrollBarBox } from "@/components/ScrollBox";
import { ComButton } from "../components/ComButton";
import { Contentpreview } from "../components/ContentPreview";
import { TarotSchema, ToolSchema } from "@/@types/tools";
import { toolStore } from "@/store/tool";
import { throttle } from "lodash";
import { useMessage } from "@/hooks/useMessage";
import { streamFetch } from "@/utils/stream";
import { deleteTarot, getTarot, gettoolhis, postCreateTarot } from "@/api/tools";
import { _Models } from "..";
import { userStore } from "@/store/user";
import { ComTextarea } from "../components/ComTextarea";
import { ComBgTextarea } from "../components/ComBgTextarea";
import { ComSex } from "../components/ComSex";

export const NaMing: FC = observer(() => {

  const [tool, SetTool] = useState<ToolSchema>()
  const bindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const textareabindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const [historyCache, setHistoryCache] = useState<TarotSchema[]>([]);
  const [choosesModel, setChoosesModel] = useState(false);
  const [modelkey, setModelsKey] = useState(_Models[1])
  const [caseStop, SetcaseStop] = useState(true);
  const message = useMessage();
  const { userInfo } = userStore;
  const { getToolByLink, getToolModel } = toolStore;
  const [buttonpd, setButtonpd] = useState(true);
  const [isnullcontent, SetIsNullContent] = useState(true);
  const [riskInfo, SetRiskInfo] = useState<any>();
  const [surname, SetSurname] = useState('');
  const [dateBirth, SetDateBirth] = useState('');
  const [timeBirth, SetTimeBirth] = useState('');
  const [generations, SetGenerations] = useState('');
  const [nameTotal, SetNameTotal] = useState('');
  const [sex, SetSex] = useState('男');

  const caseButtonClick = () => {

  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'naMing' });
      SetTool(data[0]);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      // const data = await getTarot();
      // setHistoryCache(data);
    };

    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [isContent, setIsContent] = useState(false);

  // 中断请求
  const controller = useRef(new AbortController());
  const handleGPTGenInfo = async () => {
  };

  return (<Flex key={'naMing'} className={styles.toollistBox}>
    <Flex className={styles.listBox}>
      <Box className={styles.bgbox}></Box>
      <Box zIndex={2} className={styles.topbox}>
        <ComTitle
          key={tool?.name.toString()}
          color={true}
          setViewhistory={() => { }}
          icon={tool ? tool.icon.toString() : ''}
          title={tool ? tool.name.toString() : ''}
          historyCache={historyCache}
          bindprompt={bindGenPrompt}
          historytitle='problem'
          historycontent='content'
          onDelete={async (id: string) => {

          }} />
      </Box>
      <Box className={styles.contentsBox}>
        <ScrollBarBox>
          <Box>
            <Flex className={styles.allbox}>
              <ComBgTextarea
                title={'姓氏*'}
                prompt={'请输入起名姓氏'}
                textvalue={surname}
                textchange={SetSurname}
                maxCount={10}
                rows={1}
                required={true}
              />
              <ComBgTextarea
                title={'出生日期*'}
                prompt={'请选择出生日期（以公元日期为准）'}
                textvalue={dateBirth}
                textchange={SetDateBirth}
                maxCount={30}
                rows={1}
                required={true}
                systemPromptTip={'选择出生日期时，请以公元日期为准'}
                isdatecl={true}
              />
              <ComBgTextarea
                title={'出生时间*'}
                prompt={'请请选择出生时间（如不确定，请默认选择12点）'}
                textvalue={timeBirth}
                textchange={SetTimeBirth}
                maxCount={30}
                rows={1}
                required={true}
                systemPromptTip={'若不确定具体时间，出生时间可以选择为中午12点，会对结果有一定影响'}
                istimecl={true}
              />
              <ComBgTextarea
                title={'族谱字辈'}
                prompt={'请输入族谱字辈（若无可以不填）'}
                textvalue={generations}
                textchange={SetGenerations}
                maxCount={10}
                rows={1}
              />
              <ComBgTextarea
                title={'名字总数'}
                prompt={'请输入'}
                textvalue={nameTotal}
                textchange={SetNameTotal}
                maxCount={10}
                rows={1}
              />
              <ComSex sex={sex} SetSex={SetSex} />
            </Flex>
          </Box>
        </ScrollBarBox>
      </Box>
      <Flex className={styles.footbutton}>
        <ComButton
          isnull={isnullcontent}
          loading={loading}
          color={true}
          setLoading={setLoading}
          casebut={'示例'}
          startbut={'生成'}
          stopbut={'停止'}
          stopClick={() => { controller.current?.abort() }}
          startClick={handleGPTGenInfo}
          caseStop={true}
          caseButtonClick={caseButtonClick}
          chooseModel={choosesModel}
          setChooseModel={setChoosesModel}
          modelkey={modelkey}
          setModelsKey={setModelsKey}
          _Models={_Models}
        ></ComButton>
      </Flex>
    </Flex>
    <Flex className={styles.previewBox}>
      <Contentpreview
        title={'内容预览'}
        titlePrompt={'*该结果仅供参考，请勿盲目迷信'}
        bindprompt={bindGenPrompt}
        loading={loading}
        buttonpd={buttonpd}
        isContent={isContent}
        riskInfo={riskInfo}
      />
    </Flex>
  </Flex>)
})