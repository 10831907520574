import { Flex, Text, Image, Tag, Avatar, Box, Icon, Divider } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { CButton } from "@/components/Button";
import { toolStore } from "@/store/tool";
import { observer } from "mobx-react-lite";
import { userStore } from "@/store/user";
import { useMessage } from "@/hooks/useMessage";
import { ScrollBarBox } from "@/components/ScrollBox";
import { ToolSchema } from "@/@types/tools";
import PinyinMatch from 'pinyin-match';
import { personalStore } from "@/store/personal";
import { PayRobot } from "../WechatRobot";
import { createPayVipOrder, getVipPrice } from "@/api/tools";
import { useTranslation } from "react-i18next";

const icons = [
  {
    key: '收藏',
    icon: require('@/assets/svg/toolstag/collect.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/collecthover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/collected.svg').ReactComponent
  },
  {
    key: '全部',
    icon: require('@/assets/svg/toolstag/all.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/allhover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/alled.svg').ReactComponent
  },
  {
    key: '热门',
    icon: require('@/assets/svg/toolstag/hot.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/hothover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/hoted.svg').ReactComponent
  },
  {
    key: '学习',
    icon: require('@/assets/svg/toolstag/study.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/studyhover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/studyed.svg').ReactComponent
  },

  {
    key: '办公',
    icon: require('@/assets/svg/toolstag/office.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/officehover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/officed.svg').ReactComponent
  },
  {
    key: '生活',
    icon: require('@/assets/svg/toolstag/life.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/lifehover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/lifed.svg').ReactComponent
  },
  {
    key: '自媒体',
    icon: require('@/assets/svg/toolstag/media.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/mediahover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/mediaed.svg').ReactComponent
  },
  {
    key: '电商',
    icon: require('@/assets/svg/toolstag/commerce.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/commercehover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/commerced.svg').ReactComponent
  },
  {
    key: '娱乐',
    icon: require('@/assets/svg/toolstag/fun.svg').ReactComponent,
    hovericon: require('@/assets/svg/toolstag/funhover.svg').ReactComponent,
    selecticon: require('@/assets/svg/toolstag/funed.svg').ReactComponent
  },
];
interface HomeProps {
  selectedTag: string;
  setSelectedTag: (value: string) => void;
  SetSelectTool: (value: string) => void;
}
export const Home: FC<HomeProps> = observer(({ selectedTag, setSelectedTag, SetSelectTool }) => {
  const { t } = useTranslation();
  const imgs: any[] = [
    {
      title: t('videoSummary'),
      link: 'videoSummary',
      contents: t('SupportMorePlatSource'),
      img: require('@/assets/png/video.png'),
      selects: [
        'Tiktokicon.png',
        'visionicon.png',
        'redbookicon.png',
        'Kwaiicon.png',
        'watermelonicon.png',
        'weiboicon.png',
        'bilibiliicon.png',
      ]
    }, {
      title: t('MVCaptionTrans'),
      link: 'filmtranslations',
      contents: t('MVCaptionTransDesc'),
      img: require('@/assets/png/translate.png'),
      seleces: []
    }]
  // const imgs: any[] = [require('@/assets/img/cat.jpg'), require('@/assets/img/dog.jpg')]
  const inputRef = useRef<HTMLInputElement>(null);
  const { tools, getToollist } = toolStore
  const { userInfo, postWorkTool, cancelWorkTool, setUserInfo } = userStore;
  const { worktool, getAllWorktool } = personalStore
  const message = useMessage();
  const [isLoading, setLoading] = useState(false);
  const [isSearch, SetIsSearch] = useState(false)
  const [searchValue, SetSearchValue] = useState('')
  const [searchTools, SetSearchTools] = useState<ToolSchema[]>([])
  const [collectall, setCollectAll] = useState<any>([]);
  const [payopen, setPayOpen] = useState<boolean>(false);
  const [choosePay, setChoosePay] = useState<{ discount: string; payPrice: number; price: number; month: number }>()
  useEffect(() => {
    const fetchSignInDates = async () => {
      await getToollist();
      await getAllWorktool();
      const res = await getVipPrice('wxrobot')
      setChoosePay(res.expand.price[0])
    }
    fetchSignInDates();
  }, [])
  useEffect(() => {
    const worktools = worktool as any;
    setCollectAll(worktools[0] ? worktools[0].extends : []);
  }, [worktool])
  const tagsSet = new Set();
  tools.forEach(item => {
    if (!item.tags) { return }
    item.tags.split('，').forEach(tag => {
      tag = tag.trim();
      if (tag) {
        tagsSet.add(tag);
      }
    });
  });
  const uniqueTags = Array.from(tagsSet);
  const finalTags: any[] = ['收藏', '热门', '全部', ...uniqueTags];
  const [hoverTag, setHoverTag] = useState(null);
  const searchOnChange = (event: any) => {
    SetSearchValue(event.target.value);
  }

  const handleSearchClick = () => {
    const searchValueLower = searchValue.toLowerCase();

    const filteredTools = tools.filter(item =>
      item.name.toLowerCase().includes(searchValueLower) ||
      PinyinMatch.match(item.name.toLowerCase(), searchValueLower) ||
      (item.synopsis && PinyinMatch.match(item.synopsis.toLowerCase(), searchValueLower)) ||
      (item.assembly?.ToolIndex && PinyinMatch.match(item.assembly.ToolIndex.toLowerCase(), searchValueLower))
    );
    SetSearchTools(filteredTools);
  };
  const getCollectIcon = (itemName: any) => {
    const isCollected = collectall?.some(
      (collectItem: any) =>
        collectItem.label === itemName && collectItem.status === 1
    );
    return isCollected
      ? require('@/assets/svg/toolstag/collectTool.svg').ReactComponent
      : require('@/assets/svg/toolstag/collected.svg').ReactComponent;
  };
  const onclickCollect = async (itemName: any) => {
    setLoading(true)
    try {
      const isCollected = collectall?.some(
        (collectItem: any) =>
          collectItem.label === itemName && collectItem.status === 1
      );
      if (isCollected) {
        await cancelWorkTool(itemName)
        message.success({ title: '取消收藏成功！' })
      } else {
        await postWorkTool(itemName)
        message.success({ title: '收藏成功！' })
      }
      await getAllWorktool();
      setLoading(false);
    } catch (err) {
      message.error({ title: '操作失败！请联系我们' })
    }
  };
  const isVip = (itemName: any) => {
    switch (itemName) {
      case '微信机器人':
        return userInfo?.wxRobotVip;
      case '小说推文制作':
        return userInfo?.novelVip;
      case '推文一键追爆款':
        return userInfo?.videoReVip;
      default:
        return false;
    }
  };
  const getClassNames = (isVip: any) => ({
    container: isVip ? styles.homevip : styles.homenovip,
    text: isVip ? styles.texthomevip : styles.texthomenovip,
    image: isVip ? require('@/assets/png/vip.png').ReactComponent : require('@/assets/png/novip.png').ReactComponent,
  });
  const isVipTips = (name: any) => {
    const isRelevantItem = ['微信机器人', '小说推文制作', '推文一键追爆款'].includes(name);
    if (isRelevantItem) {
      const vipStatus = isVip(name);
      const classNames = getClassNames(vipStatus);
      return (
        <Flex className={classNames.container} onClick={(e) => { e.stopPropagation(); setPayOpen(true) }}>
          <Image src={vipStatus ? require('@/assets/png/vip.png')
            : require('@/assets/png/novip.png')} />
          <Text className={classNames.text}>vip</Text>
        </Flex>
      );
    }
  }
  const clearInput = () => {
    SetSearchValue(''); // 清空输入框内容
    if (inputRef.current) {
      inputRef.current.focus(); // 重新聚焦到输入框
    }
  };
  const handleClickPay = async () => {
    try {
      const res = await createPayVipOrder('wxrobot', Number(choosePay?.month))
      setUserInfo(res.user)
      message.success({ title: '支付成功' })
      setPayOpen(false)
    } catch (err: any) {
      console.log(err)
      message.error({ title: err.message })
    }
  }
  return (
    <Flex className={styles.allbox}>
      <ScrollBarBox className={styles.homebox}>
        <Flex className={styles.titlebox}>
          <input type="text" className={styles.titleinput} placeholder="搜索"
            ref={inputRef}
            value={searchValue} onChange={searchOnChange}
            onBlur={() => {
              if (searchValue.length < 1) {
                SetIsSearch(false)
              }
            }} onFocus={() => { SetIsSearch(true) }}
          />
          <Icon className={styles.titleicon} as={require('@/assets/svg/magnifier.svg').ReactComponent} />
          <Divider className={styles.titledivider} orientation='vertical' />
          {
            searchValue.length > 0 && <Icon className={styles.emptyIcon} as={require('@/assets/svg/toolsEmpty.svg').ReactComponent}
              onClick={clearInput} />
          }
          {/* <CButton className={styles.titlebut} onClick={() => { handleSearchClick() }}>搜索</CButton> */}
        </Flex>
        {(isSearch && searchValue.length > 0) ? (
          <Flex className={styles.toolssearchbox}>
            {tools.some(item =>
              item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              PinyinMatch.match(item.name.toLowerCase(), searchValue.toLowerCase()) ||
              (item.synopsis && PinyinMatch.match(item.synopsis.toLowerCase(), searchValue.toLowerCase())) ||
              (item.assembly.ToolIndex && PinyinMatch.match(item.assembly.ToolIndex.toLowerCase(), searchValue.toLowerCase()))
            ) ? (
              <ScrollBarBox className={styles.toolsbotlist}>
                {tools.map((item, index) => {
                  if (
                    item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                    PinyinMatch.match(item.name.toLowerCase(), searchValue.toLowerCase()) ||
                    (item.synopsis && PinyinMatch.match(item.synopsis.toLowerCase(), searchValue.toLowerCase())) ||
                    (item.assembly.ToolIndex && PinyinMatch.match(item.assembly.ToolIndex.toLowerCase(), searchValue.toLowerCase()))
                  ) {
                    if (item.assembly.status === "不公开" && (userInfo?.isWhiteList === undefined || userInfo?.isWhiteList !== 1)) {
                      return null;
                    }
                    return (
                      <Flex
                        key={'key' + item._id + index}
                        className={styles.flexContainer}
                        onClick={() => { SetSelectTool(item.assembly.link); setSelectedTag('全部'); }}
                      >
                        {isVipTips(item.name)}
                        <Box className={styles.collectionbox}>
                          <Icon as={getCollectIcon(item.name)} onClick={(e) => { e.stopPropagation(); onclickCollect(item.name) }} />
                        </Box>
                        <Image src={item.icon} className={styles.avatar} />
                        <Box className={styles.contentbox}>
                          <Text className={styles.contName}>{item.name}</Text>
                          <Text className={styles.contSynopsis}>{item.synopsis}</Text>
                        </Box>
                      </Flex>
                    );
                  }
                })}
              </ScrollBarBox>
            ) : (
              <Box className={styles.noChat}>
                <Image src={require('@/assets/png/Uncommission.png')} />
                <Text>暂无结果，请重新搜索</Text>
              </Box>
            )}
          </Flex>
        ) : (
          <>
            <Flex>
              {imgs.map((item, index) => (
                <Box className={styles.imgbox} key={index} onClick={() => SetSelectTool(item.link)}>
                  <Image key={index} className={styles.imgboximg} src={item.img} />
                  <Flex className={styles.imgContentBox}>
                    <Text className={index === 0 ? styles.firstImgTitle : styles.secondImgTitle}>
                      {item.title}
                    </Text>
                    <Text className={index === 0 ? styles.firstImgcontent : styles.secondImgcontent}>
                      {item.contents}
                    </Text>
                    {item.selects && (
                      <Flex className={styles.imgsvgs}>
                        {item.selects.map((item: any) => {
                          return (
                            <Image key={item} src={require(`@/assets/img/toolsicon/${item}`)} />
                          )
                        })}
                      </Flex>
                    )}
                  </Flex>
                </Box>
              ))}
            </Flex>
            <Flex className={styles.tagsbox}>
              {finalTags.map((itme, index) => {
                const iconObj = icons.find((icon) => icon.key === itme)!;
                const isSelected = selectedTag === itme;
                const isHovered = hoverTag === itme;
                const currentIcon = isSelected
                  ? iconObj.hovericon
                  : isHovered
                    ? iconObj.hovericon
                    : iconObj.icon;
                return (
                  <Box key={itme + index}
                    className={`${styles.tagbox} ${isSelected ? styles.selected : ''}`}
                    onClick={() => setSelectedTag(itme)}
                    onMouseEnter={() => setHoverTag(itme)}
                    onMouseLeave={() => setHoverTag(null)}
                  >
                    {currentIcon && <Icon className={styles.tagsicon} as={currentIcon} />}
                    <Text className={styles.tagstext}>{t(itme)}</Text>
                  </Box>
                )
              })}
            </Flex>
            <Flex className={styles.toolsbotbox}>
              <Text className={styles.toolsboxtitle}>
                {selectedTag === '收藏' ? t('MyCollectTool')
                  : selectedTag === '热门' ? t('HotToolRank')
                    : `${t(selectedTag)} ${t('Tool')}`}</Text>
              <ScrollBarBox className={styles.toolsbotlist}>
                {(selectedTag === "收藏" && collectall.filter((item: any) => item.status === 1).length < 1) ?
                  <Flex className={styles.notCollect}>
                    <Image src={require('@/assets/png/toolNotCollect.png')} />
                    <Text>{t('NoToolToSee')}<span onClick={() => setSelectedTag('全部')}>{t('AllTool')}</span></Text>
                  </Flex> :
                  tools.map((item, index) => {
                    if (
                      item.tags && item.tags.includes(selectedTag) ||
                      (selectedTag === "热门" &&
                        item.assembly.Incomplete && item.assembly.Incomplete.length > 1 && item.assembly.Incomplete.includes('火爆')) ||
                      (selectedTag === "全部") ||
                      (selectedTag === "收藏" &&
                        collectall &&
                        collectall.some(
                          (collectItem: any) =>
                            collectItem.label === item.name && collectItem.status === 1
                        ))
                    ) {
                      if (item.assembly.status === "不公开" && (userInfo?.isWhiteList === undefined || userInfo?.isWhiteList !== 1)) {
                        return null;
                      }
                      return (
                        <Flex
                          key={'key' + item._id + index}
                          className={styles.flexContainer}
                          onClick={() => SetSelectTool(item.assembly.link)}
                        >
                          {isVipTips(item.name)}
                          <Box className={styles.collectionbox}>
                            <Icon as={getCollectIcon(item.name)} onClick={(e) => { e.stopPropagation(); onclickCollect(item.name) }} />
                          </Box>
                          <Image src={item.icon} className={styles.avatar} />
                          <Box className={styles.contentbox}>
                            <Text className={styles.contName}>{t(item.name)}</Text>
                            <Text className={styles.contSynopsis}>{item.synopsis}</Text>
                          </Box>
                        </Flex>
                      )
                    }
                  })}
              </ScrollBarBox>
            </Flex>
          </>
        )}
      </ScrollBarBox>
      <PayRobot open={payopen} setOpen={setPayOpen} okopen={handleClickPay} />
    </Flex>
  );
})